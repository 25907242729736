import { useMutation, useQueryClient } from '@tanstack/react-query';
import Interpreters from './Interpreters';

export const interpretersQueryKeys = {
  all: ['interpreters'] as const,
  blockedInterpreterStatus: (id: string) =>
    [...interpretersQueryKeys.all, 'blockedInterpreterStatus', id] as const,
};

export const useBlockInterpreterMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: string) => Interpreters.blockInterpreter(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({
        queryKey: interpretersQueryKeys.blockedInterpreterStatus(id),
      });
    },
  });

  return mutation;
};

export const useUnblockInterpreterMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: string) => Interpreters.unblockInterpreter(id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({
        queryKey: interpretersQueryKeys.blockedInterpreterStatus(id),
      });
    },
  });

  return mutation;
};
