import { InterpretersTimelineFilterField } from './InterpretersTimelineFilters';

const getInterpretersTimelineFilters = (
  currentFilters: Record<string, string>,
  field: InterpretersTimelineFilterField,
  value: string,
): Record<string, string> => {
  if (field === InterpretersTimelineFilterField.Name) {
    return { ...currentFilters, ...{ 's[name]': value } };
  }

  if (field === InterpretersTimelineFilterField.Qualification) {
    return { ...currentFilters, ...{ 's[qualification]': value } };
  }

  if (field === InterpretersTimelineFilterField.Gender) {
    return { ...currentFilters, ...{ 's[gender]': value } };
  }

  if (field === InterpretersTimelineFilterField.Distance) {
    return { ...currentFilters, ...{ 's[distance]': value } };
  }

  if (field === InterpretersTimelineFilterField.AlternativeLanguage) {
    return { ...currentFilters, ...{ 's[alternativeLanguage]': value } };
  }

  if (field === InterpretersTimelineFilterField.StandbyInterpreters) {
    return { ...currentFilters, ...{ 's[standbyInterpreters]': value } };
  }

  return currentFilters;
};

export default getInterpretersTimelineFilters;
