import React from 'react';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import avatarPlaceholder from '../assets/avatar-placeholder.svg';
import styles from './Avatar.module.scss';

export interface AvatarProps {
  altText: string;
  url?: string;
  active?: boolean;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

const Avatar = ({ altText, url, active, size = 'small', className }: AvatarProps) => {
  const src = url && !url.includes('/avatars/original/missing.png') ? url : avatarPlaceholder;

  return (
    <div className={classnames(styles.avatar, className)} data-testid="avatar">
      <img src={src} alt={altText} className={styles[size]} />
      {active && <FontAwesomeIcon data-testid='avatar-active-icon' icon={faCircleCheck} className={styles.activeIcon} />}
    </div>
  );
};

export default Avatar;
