import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCalendar, faFileInvoice } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import {
  getBookingMechanismIcon,
  getBookingMechanismLabel,
  getFeesLabels,
} from '../../../CreateInterpretationOrder/utils';
import translationKeys from '../../../translations/translationKeys';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import EditInvoicingDrawer from '../EditInvoicingDrawer/EditInvoicingDrawer';
import {
  JobDetailsDrawerName,
  useJobDetailsDrawer,
} from '../JobDetailsDrawerContext/JobDetailsDrawerContext';
import Card from './Card/Card';

const InvoicingCard = () => {
  const titleIcon = <FontAwesomeIcon icon={faFileInvoice} />;
  const intl = useIntl();
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const { openDrawer } = useJobDetailsDrawer();

  if (!job) return null;

  const areFeesVisible =
    job.info.manualBookingFeeApplied ||
    job.info.manualEditingFeeApplied ||
    job.info.manualTravelBookingFeeApplied;

  return (
    <>
      <Card
        titleIcon={titleIcon}
        title={intl.formatMessage({ id: translationKeys.job_invoicing_label })}
        handleEdit={() => openDrawer(JobDetailsDrawerName.EditInvoicingDrawer)}
      >
        {job.actualCreatedAt && (
          <>
            <Card.BodyItem
              title={intl.formatMessage({ id: translationKeys.job_invoicing_booked_at })}
            >
              <Card.ItemRow>
                <FontAwesomeIcon icon={faCalendar} />
                {format(new Date(job.actualCreatedAt), 'dd.MM.yyyy, HH:mm')}{' '}
                <FormattedMessage id={translationKeys.job_invoicing_booked_by} />{' '}
                <FontAwesomeIcon icon={getBookingMechanismIcon(job.bookingMechanism)} />
                <span>{getBookingMechanismLabel(job.bookingMechanism, intl)}</span>
              </Card.ItemRow>
            </Card.BodyItem>
            {areFeesVisible && (
              <Card.BodyItem title={intl.formatMessage({ id: translationKeys.job_invoicing_fees })}>
                <Card.ItemRow>
                  {getFeesLabels(
                    !!job.info.manualBookingFeeApplied,
                    !!job.info.manualEditingFeeApplied,
                    !!job.info.manualTravelBookingFeeApplied,
                    intl,
                  )}
                </Card.ItemRow>
              </Card.BodyItem>
            )}
            {(job.actualCreatedAt || areFeesVisible) && <Card.Separator />}
            <Card.GridRow>
              <Card.BodyItem title={job.referenceSettings.labelForBookingReference || intl.formatMessage({ id: translationKeys.booking_reference_default_label })}>
                {job.info.bookingReference || '-'}
              </Card.BodyItem>

              <Card.BodyItem title={job.referenceSettings.labelForPaymentBookingReference || intl.formatMessage({ id: translationKeys.payment_booking_reference_default_label })}>
                {job.info.paymentBookingReference || '-'}
              </Card.BodyItem>
            </Card.GridRow>

            <Card.BodyItem title={job.referenceSettings.labelForCaseNumber || intl.formatMessage({ id: translationKeys.case_number_default_label })}>
              {job.info.caseNumber || '-'}
            </Card.BodyItem>
            <Card.BodyItem
              title={intl.formatMessage({ id: translationKeys.job_invoicing_agreement })}
            >
              {job.agreementName || '-'}
            </Card.BodyItem>
          </>
        )}
      </Card>
      <EditInvoicingDrawer job={job} />
    </>
  );
};

export default InvoicingCard;
