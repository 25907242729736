import { useEffect, useRef } from 'react';

export default function useSyncTimelineWithScroll() {
  const timelineAxisRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!timelineAxisRef.current || !scrollableRef.current) return;

    const timelineAxis = timelineAxisRef.current;
    const scrollable = scrollableRef.current;

    function handleScroll() {
      timelineAxis.style.transform = `translateX(-${scrollable.scrollLeft}px)`;
    }

    scrollable.addEventListener('scroll', handleScroll);

    return () => {
      scrollable.removeEventListener('scroll', handleScroll);
    };
  }, [timelineAxisRef.current, scrollableRef.current]);

  return { timelineAxisRef, scrollableRef };
}
