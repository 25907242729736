import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faBan,
  faBuilding,
  faCircleCheck,
  faClone,
  faFileInvoice,
  faLanguage,
  faMemoPad,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@skiwo/components';
import { useGetJobQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import routes from '../../../helpers/routes';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobStatus } from '../../../types';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import { jobSectionVisibilityGuard } from '../../utils/jobSectionVisibilityGuard';
import { CancelJobModal } from '../CancelJobModal/CancelJobModal';
import FinishJobModal from '../FinishJobModal/FinishJobModal';
import {
  JobDetailsDrawerName,
  useJobDetailsDrawer,
} from '../JobDetailsDrawerContext/JobDetailsDrawerContext';
import SystemLogDrawer from '../SystemLogDrawer/SystemLogDrawer';
import styles from './ActionButtonsSection.module.scss';

export const ActionButtonsSection = () => {
  const jobId = useGetJobIdFromParam();
  const { data: job } = useGetJobQuery(jobId);
  const { openDrawer } = useJobDetailsDrawer();
  const intl = useIntl();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showFinishModal, setShowFinishModal] = useState(false);

  if (!job) return null;

  return (
    <div className={styles.actionButtons}>
      <div className={styles.buttonsWrapper}>
        {job.status === ManagerJobStatus.InProgress && (
          <Button
            size="large"
            variant="primary"
            onClick={() => setShowFinishModal(true)}
            icon={<FontAwesomeIcon icon={faCircleCheck} />}
          >
            {intl.formatMessage({ id: translationKeys.job_finish_job_button })}
          </Button>
        )}
        <Button
          size="large"
          variant="gray"
          onClick={() => openDrawer(JobDetailsDrawerName.EditInformationDrawer)}
          icon={<FontAwesomeIcon icon={faLanguage} />}
        >
          {intl.formatMessage({ id: translationKeys.job_edit_information_button })}
        </Button>
        <Button
          size="large"
          variant="gray"
          onClick={() => openDrawer(JobDetailsDrawerName.EditCustomerDrawer)}
          icon={<FontAwesomeIcon icon={faBuilding} />}
        >
          {intl.formatMessage({ id: translationKeys.job_edit_customer_button })}
        </Button>
        <Button
          size="large"
          variant="gray"
          onClick={() => openDrawer(JobDetailsDrawerName.EditInvoicingDrawer)}
          icon={<FontAwesomeIcon icon={faFileInvoice} />}
        >
          {intl.formatMessage({ id: translationKeys.job_edit_invoicing_button })}
        </Button>
        <Button
          size="large"
          variant="gray"
          href={`${routes.createInterpretationOrder}?jobid=${jobId}`}
          icon={<FontAwesomeIcon icon={faClone} />}
          target="_blank"
        >
          {intl.formatMessage({ id: translationKeys.job_copy_button })}
        </Button>
        {jobSectionVisibilityGuard({
          currentStatus: job.status,
          statuses: [
            ManagerJobStatus.Published,
            ManagerJobStatus.Applied,
            ManagerJobStatus.Accepted,
            ManagerJobStatus.InProgress,
          ],
        }) && (
          <Button
            icon={<FontAwesomeIcon icon={faBan} />}
            variant="gray"
            className={styles.cancelButton}
            size="large"
            onClick={() => setShowCancelModal(true)}
          >
            <FormattedMessage id={translationKeys.job_cancel_button} />
          </Button>
        )}
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          size="large"
          variant="gray"
          onClick={() => openDrawer(JobDetailsDrawerName.SystemLogDrawer)}
          icon={<FontAwesomeIcon icon={faMemoPad} />}
        >
          <FormattedMessage id={translationKeys.job_system_log_label} />
        </Button>
      </div>
      <CancelJobModal show={showCancelModal} onClose={() => setShowCancelModal(false)} />
      <FinishJobModal show={showFinishModal} onClose={() => setShowFinishModal(false)} />
      <SystemLogDrawer />
    </div>
  );
};
