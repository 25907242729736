import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Drawer,
  PhoneCodeDropdown,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import EnterpriseCard from '../../components/EnterpriseCard/EnterpriseCard';
import { emailRegex, phoneRegex } from '../../helpers/regexPatterns';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import translationKeys from '../../translations/translationKeys';
import Enterprise from '../../types/Enterprise';
import EnterpriseMember from '../../types/EnterpriseMember';
import CreateDepartmentSuccess from './CreateDepartmentSuccess/CreateDepartmentSuccess';
import styles from './CreateDepartmentDrawer.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
  onComplete?: () => void;
}

interface FormValues {
  departmentName: string;
  email: string;
  phoneNumber: string;
  contactAddressIds: number;
  memberUids: string;
}

const CreateDepartmentDrawer = ({ show, onClose, onComplete }: Props) => {
  const intl = useIntl();

  const [selectedAddressesId, setSelectedAddressesId] = useState<number[]>([]);
  const [selectedMembersId, setSelectedMembersId] = useState<number[]>([]);
  const [contactAddressesResult, setContactAddressesResult] = useState<SearchDropdownMenuOption[]>(
    [],
  );
  const [enterpriseResults, setEnterpriseResults] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<SearchDropdownMenuOption>();
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<SearchDropdownMenuOption>();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [enterprise, setEnterprise] = useState<Enterprise>();
  const [newDepartmentName, setNewDepartmentName] = useState<string>('');
  const [enterpriseId, setEnterpriseId] = useState('');
  const [memberResults, setMembersResults] = useState<SearchDropdownMenuOption[]>([]);
  const [departmentNameError, setDepartmentNameError] = useState(false);
  const api = useApi();

  const resetShowSuccess = () => setShowSuccess(false);

  const resetSelectedEnterprise = () => {
    setSelectedEnterprise(undefined);
  };

  const debounceEnterpriseSearch = useDebounce(300);

  const schema = yup.object().shape({
    departmentName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.create_department_drawer_department_input_label,
          }),
        },
      ),
    ),
    phoneNumber: yup
      .string()
      .notRequired()
      .matches(
        phoneRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_department_drawer_phone_number_input,
            }),
          },
        ),
      ),
    email: yup
      .string()
      .matches(
        emailRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_department_drawer_email_input,
            }),
          },
        ),
      )
      .notRequired(),
  });

  const getEnterpriseAddresses = async () => {
    const { data } = await api.getEnterpriseAddresses(enterpriseId);
    if (data?.contact_addresses) {
      const contactAddressesOptions =
        data.contact_addresses.map((contactAddresses) => {
          return {
            id: Number(contactAddresses.id),
            label: `${contactAddresses.line1}, ${contactAddresses.postcode} ${contactAddresses.city}`,
            key: contactAddresses.id.toString(),
          };
        }) || [];

      setContactAddressesResult(contactAddressesOptions);
    }
  };

  const getEnterpriseList = async (query: string) => {
    const { data } = await api.searchEnterprises({ query });

    if (data?.enterprises) {
      const uniqueEnterprise = data?.enterprises.filter((obj, index, array) => {
        return array.findIndex((enterprise) => enterprise.orgNumber === obj.orgNumber) === index;
      });

      const enterpriseOptions = (uniqueEnterprise || [])
        .filter((enterprise) => enterprise.orgNumber !== undefined)
        .map((enterprise) => {
          const isEnterpriseId =
            'id' in enterprise && enterprise.id !== undefined
              ? translationKeys.create_customer_drawer_group_enterprise
              : translationKeys.create_customer_drawer_group_brreg;

          return {
            id: enterprise.id,
            label: enterprise.name,
            key: enterprise.orgNumber,
            subtitle: `Org. nr. ${enterprise.orgNumber}`,
            group: intl.formatMessage({
              id: isEnterpriseId,
            }),
          };
        });

      setEnterpriseResults(enterpriseOptions);
    }
  };

  const getEnterprise = async () => {
    if (!selectedEnterprise) return;
    const { data } = await api.getEnterpriseDetails(selectedEnterprise.id.toString());

    // TODO: Why is the enterprise id optional?
    if (data?.enterprise && data?.enterprise?.id) {
      setEnterprise(data.enterprise);
      // TODO: Why do we need this if we have the enterprise?
      setEnterpriseId(data.enterprise.id.toString());
    }
  };

  const getEnterpriseMembers = async () => {
    if (!enterpriseId) {
      return;
    }

    const { data } = await api.getEnterpriseMembers(enterpriseId);
    if (data?.enterprise_members) {
      const memberOptions = data.enterprise_members.map((enterprise_member: EnterpriseMember) => {
        return {
          id: enterprise_member.uid,
          label: [enterprise_member.firstName, enterprise_member.lastName].join(' '),
          key: enterprise_member.uid,
        };
      });
      setMembersResults(memberOptions);
    }
  };

  const departmentNameErrorMessage = departmentNameError
    ? intl.formatMessage({ id: 'create_department_drawer_duplicate_name_error' })
    : '';

  const handleSubmit = async (values: FormValues, formActions: FormikHelpers<FormValues>) => {
    const payload = {
      name: values.departmentName,
      email: values.email,
      phone_code: values.phoneNumber ? selectedPhoneCode?.key || '+47' : '',
      phone_number: values.phoneNumber,
      contact_address_ids: selectedAddressesId,
      member_uids: selectedMembersId,
    };

    const { data } = await api.createEnterpriseDepartment(enterpriseId, {
      department: payload,
    });

    if (data && data.department) {
      setShowSuccess(true);
      setDepartmentNameError(false);
      setSelectedPhoneCode(undefined);
      !!onComplete && onComplete();
      formActions.resetForm();
    } else {
      setDepartmentNameError(true);
    }
  };

  const handleAddressesChange = (addresses: number[]) => {
    setSelectedAddressesId(addresses);
  };

  const handleMembersChange = (members: number[]) => {
    setSelectedMembersId(members);
  };

  useEffect(() => {
    if (show) {
      getEnterpriseList('');
    }
  }, [show]);

  useEffect(() => {
    if (enterpriseId) {
      getEnterpriseMembers();
      getEnterpriseAddresses();
    }
  }, [enterpriseId]);

  useEffect(() => {
    if (selectedEnterprise?.id) {
      getEnterprise();
    }
  }, [selectedEnterprise]);

  return (
    <Drawer
      show={show}
      onClose={() => {
        onClose();
        resetShowSuccess();
        setSelectedEnterprise(undefined);
        setDepartmentNameError(false);
        setSelectedPhoneCode(undefined);
      }}
      title={
        showSuccess
          ? intl.formatMessage({ id: translationKeys.create_department_drawer_success_title })
          : intl.formatMessage({ id: translationKeys.create_department_drawer_title })
      }
      data-testid="create-department-drawer"
    >
      {showSuccess ? (
        <CreateDepartmentSuccess
          onClose={onClose}
          resetShowSuccess={resetShowSuccess}
          resetSelectedEnterprise={resetSelectedEnterprise}
          newDepartmentName={newDepartmentName}
          enterpriseName={selectedEnterprise?.label}
        />
      ) : (
        <>
          <div className={styles.container}>
            <div data-testid="enterprise-dropdown">
              <SearchDropdown
                options={enterpriseResults}
                placeholder={intl.formatMessage({
                  id: translationKeys.create_department_drawer_enterprise_input_placeholder,
                })}
                label={intl.formatMessage({
                  id: translationKeys.create_department_drawer_enterprise_input_label,
                })}
                size="large"
                search
                grouped
                onSearch={(query: string) => {
                  debounceEnterpriseSearch(() => {
                    getEnterpriseList(query);
                  });
                }}
                onChange={(enterprise) => {
                  if (enterprise && enterprise.length > 0) {
                    setSelectedEnterprise(enterprise[0]);
                  }
                }}
              />
            </div>

            {selectedEnterprise && enterprise && (
              <div className={styles.selectedEnterpriseContainer}>
                <EnterpriseCard enterprise={enterprise} />

                <Formik
                  validationSchema={schema}
                  onSubmit={(values, formActions) => {
                    handleSubmit(values, formActions);
                  }}
                  initialValues={{
                    departmentName: '',
                    email: '',
                    phoneNumber: '',
                    contactAddressIds: 0,
                    memberUids: '',
                  }}
                >
                  {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                    <Form onSubmit={handleSubmit} className={styles.formContainer}>
                      <TextField
                        placeholder={intl.formatMessage({
                          id: translationKeys.create_department_drawer_department_input_placeholder,
                        })}
                        label={intl.formatMessage({
                          id: translationKeys.create_department_drawer_department_input_label,
                        })}
                        type="text"
                        name="departmentName"
                        size="large"
                        required
                        onChange={(e) => {
                          handleChange(e);
                          setNewDepartmentName(e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.departmentName}
                        errorText={
                          (touched.departmentName ? errors.departmentName : undefined) ||
                          departmentNameErrorMessage
                        }
                      />

                      <SearchDropdown
                        options={contactAddressesResult}
                        placeholder={intl.formatMessage({
                          id: translationKeys.create_department_drawer_address_input_placeholder,
                        })}
                        label={intl.formatMessage({
                          id: translationKeys.create_department_drawer_address_input_label,
                        })}
                        size="large"
                        multiple
                        onChange={(addresses) => {
                          if (addresses && addresses.length > 0 && addresses[0].key) {
                            const addressIds = addresses.map((address) => address.id);
                            handleAddressesChange(addressIds);
                          } else {
                            handleAddressesChange([]);
                          }
                        }}
                      />

                      <div data-testid="phone-code-dropdown">
                        <div className={styles.phoneInputs}>
                          <PhoneCodeDropdown
                            selected={selectedPhoneCode}
                            placeholder="+47"
                            label={intl.formatMessage({
                              id: translationKeys.create_department_drawer_country_code_input_label,
                            })}
                            onChange={(phoneCodes) => {
                              if (phoneCodes && phoneCodes.length > 0 && phoneCodes[0].key) {
                                setSelectedPhoneCode(phoneCodes[0]);
                              } else {
                                setSelectedPhoneCode(undefined);
                              }
                            }}
                          />

                          <TextField
                            placeholder={intl.formatMessage({
                              id: translationKeys.create_department_drawer_phone_number_input,
                            })}
                            type="text"
                            label={intl.formatMessage({
                              id: translationKeys.create_department_drawer_phone_number_input,
                            })}
                            size="large"
                            name="phoneNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber}
                            errorText={touched.phoneNumber ? errors.phoneNumber : undefined}
                            data-testid="phone-number-input"
                          />
                        </div>
                        <p className={styles.phoneHelpText}>
                          <FormattedMessage
                            id={translationKeys.create_department_drawer_phone_help_text}
                          />
                        </p>
                      </div>

                      <div>
                        <TextField
                          placeholder={intl.formatMessage({
                            id: translationKeys.create_department_drawer_email_input,
                          })}
                          type="email"
                          label={intl.formatMessage({
                            id: translationKeys.create_department_drawer_email_input,
                          })}
                          size="large"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          errorText={touched.email ? errors.email : undefined}
                        />
                        <p className={styles.emailHelpText}>
                          <FormattedMessage
                            id={translationKeys.create_department_drawer_email_help_text}
                          />
                        </p>
                      </div>

                      <SearchDropdown
                        size="large"
                        data-testid="member-dropdown"
                        options={memberResults}
                        placeholder={intl.formatMessage({
                          id: translationKeys.create_department_drawer_department_member_select_placeholder,
                        })}
                        label={intl.formatMessage({
                          id: translationKeys.create_department_drawer_department_member_select_label,
                        })}
                        onChange={(members) => {
                          if (members && members.length > 0 && members[0].key) {
                            const membersUids = members.map((member) => member.id);
                            handleMembersChange(membersUids);
                          } else {
                            handleMembersChange([]);
                          }
                        }}
                        multiple
                      />

                      <div className={styles.actions}>
                        <Button
                          size="large"
                          variant="gray"
                          onClick={() => {
                            onClose();
                            resetSelectedEnterprise();
                          }}
                          data-testid="cancel-button"
                        >
                          <FormattedMessage
                            id={translationKeys.create_department_drawer_cancel_button}
                          />
                        </Button>

                        <Button size="large" type="submit">
                          <FormattedMessage
                            id={translationKeys.create_department_drawer_submit_button}
                          />
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </>
      )}
    </Drawer>
  );
};

export default CreateDepartmentDrawer;
