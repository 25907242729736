import { useEffect, useRef } from 'react';
import { ManagerJobSuitableInterpreter } from '../../../../types/ManagerJobSuitableInterpreter';

export default function useScrollTimeslotIntoView(
  interpretersData?: ManagerJobSuitableInterpreter[] | null,
) {
  const selectedTimeslotRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedTimeslotRef.current) {
      const parent = selectedTimeslotRef.current.parentElement; // Ensure the parent is an Element
      if (parent) {
        const childRect = selectedTimeslotRef.current.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();

        parent.scrollTo({
          left:
            childRect.left -
            parentRect.left +
            parent.scrollLeft -
            parent.clientWidth / 2 +
            selectedTimeslotRef.current.offsetWidth / 2,
          behavior: 'auto',
        });
      }
    }
  }, [interpretersData]);

  return selectedTimeslotRef;
}
