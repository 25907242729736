import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faMagicWandSparkles, faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@skiwo/components';
import { AvailabilityTimelineMeeting } from '@skiwo/components/src/AvailabilityTimeline/AvailabilityTimeline';
import { differenceInCalendarDays } from 'date-fns';
import { useGetSuitableInterpretersQuery } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import {
  getAssignmentTypeIcon,
  getAssignmentTypeLabel,
} from '../../../CreateInterpretationOrder/utils';
import translationKeys from '../../../translations/translationKeys';
import {
  InterpreterAvailability,
  InterpreterAvailabilityEventType,
} from '../../../types/InterpreterAvailability';
import { ManagerJobSuitableInterpreter } from '../../../types/ManagerJobSuitableInterpreter';
import { getHoursArray } from './helpers/InterpretersTimeline.helpers';
import useScrollTimeslotIntoView from './helpers/useScrollTimeslotIntoView';
import useSyncTimelineWithScroll from './helpers/useSyncTimelineWithScroll';
import PersonCellSkeleton from './PersonCellSkeleton/PersonCellSkeleton';
import getInterpretersTimelineFilters from './getInterpretersTimelineFilters';
import {
  InterpretersTimelineFilterField,
  InterpretersTimelineFilters,
} from './InterpretersTimelineFilters';
import PersonCell from './PersonCell';
import TimeslotPill from './TimeslotPill';
import styles from './InterpretersTimeline.module.scss';

type Filters = Record<string, string>;

type Meeting = {
  start: Date;
  end: Date;
  variant?: 'selected' | 'green' | 'gray' | 'default';
  icon?: JSX.Element;
  title?: string;
};

export interface InterpretersTimelineProps {
  jobId: string;
  selectedTimeslot: Omit<Meeting, 'variant'>;
}

export default function InterpretersTimeline({
  jobId,
  selectedTimeslot,
}: InterpretersTimelineProps) {
  const intl = useIntl();
  const [interpreters, setInterpreters] = useState<ManagerJobSuitableInterpreter[]>([]);
  const selectedTimeslotDays = differenceInCalendarDays(
    selectedTimeslot.end,
    selectedTimeslot.start,
  );

  const gridHours = 24 + selectedTimeslotDays * 24;
  const hoursArr = getHoursArray(gridHours);
  const [cellWidth, setCellWidth] = useState(0);
  const { scrollableRef, timelineAxisRef } = useSyncTimelineWithScroll();

  const [filters, setFilters] = useState<Filters>({ page: '1' });

  const { data: interpretersData, isLoading } = useGetSuitableInterpretersQuery({
    id: jobId,
    urlParams: { ...filters, items: 6 },
  });
  const selectedTimeslotRef = useScrollTimeslotIntoView(interpreters);

  const getItemPosition = (start: Date, end: Date) => {
    if (!cellWidth) return { left: 0, width: 0 };
    const daysInHours = differenceInCalendarDays(end, start) * 24;

    const startPos = start.getHours() * cellWidth + start.getMinutes() * (cellWidth / 60);
    const endPos = (end.getHours() + daysInHours) * cellWidth + end.getMinutes() * (cellWidth / 60);

    return {
      left: startPos,
      width: endPos - startPos,
    };
  };

  const handleFilterChange = (field: InterpretersTimelineFilterField, value: string) => {
    const newFilters = getInterpretersTimelineFilters(filters, field, value);
    setFilters(newFilters);
  };

  const serializeMeetings = (data: InterpreterAvailability) => {
    const jobMeetings: AvailabilityTimelineMeeting[] = data.jobs.map((job) => {
      return {
        title: getAssignmentTypeLabel(job.sessionType, intl),
        start: new Date(job.startTime),
        end: new Date(job.finishTime),
        variant: 'default',
        icon: <FontAwesomeIcon icon={getAssignmentTypeIcon(job.sessionType)} />,
      };
    });

    const eventMeetings: AvailabilityTimelineMeeting[] = data.events.map((event) => {
      if (event.eventType === InterpreterAvailabilityEventType.StandByTime) {
        return {
          title: intl.formatMessage({
            id: translationKeys.create_interpretation_order_settings_specific_interpreter_standby_block,
          }),
          type: InterpreterAvailabilityEventType.StandByTime,
          start: new Date(event.startTime),
          end: new Date(event.finishTime),
          variant: 'green',
          icon: <FontAwesomeIcon icon={faMagicWandSparkles} />,
        };
      } else {
        return {
          title: 'N/A',
          start: new Date(event.startTime),
          end: new Date(event.finishTime),
          variant: 'default',
          icon: <FontAwesomeIcon icon={faXmarkCircle} />,
        };
      }
    });

    const offTimeMeetings: AvailabilityTimelineMeeting[] = data.offTimes.map((offTime) => {
      return {
        start: new Date(offTime.startTime),
        end: new Date(offTime.finishTime),
        title: 'N/A',
        variant: 'default',
        icon: <FontAwesomeIcon icon={faXmarkCircle} />,
      };
    });

    const travelMeetings: AvailabilityTimelineMeeting[] = data.travelTimes.map((travelTime) => {
      return {
        start: new Date(travelTime.startTime),
        end: new Date(travelTime.finishTime),
        variant: 'gray',
      };
    });

    return [
      ...eventMeetings.filter((meeting) => meeting.variant === 'green'),
      ...eventMeetings.filter((meeting) => meeting.variant !== 'green'),
      ...jobMeetings,
      ...offTimeMeetings,
      ...travelMeetings,
    ];
  };

  useEffect(() => {
    if (!interpretersData) return;

    const appendData = interpretersData.pagination.page > 1;
    setInterpreters(
      appendData
        ? (prev) => [...prev, ...interpretersData.collection]
        : interpretersData.collection,
    );
  }, [interpretersData]);

  return (
    <div className={styles.interpretersTimeline}>
      <InterpretersTimelineFilters filters={filters} onFilterChange={handleFilterChange} />
      <div className={styles.twoColGrid}>
        <div className={styles.timelineOverflow} />
        <div
          ref={timelineAxisRef}
          className={styles.timeGrid}
          style={{ gridTemplateColumns: `repeat(${gridHours + 1}, 10rem)` }}
        >
          {hoursArr.map((hour) => (
            <div key={hour} className={styles.timeCell}>
              {hour}
            </div>
          ))}
          <div ref={(ref) => setCellWidth(ref?.offsetWidth ?? 0)} className={styles.timeCell}>
            {hoursArr[0]}
          </div>
        </div>
      </div>
      <div className={styles.twoColGrid}>
        <div>
          {interpreters.map((interpreter) => (
            <div key={interpreter.person.uid} className={styles.timelineCell}>
              <PersonCell interpreter={interpreter} />
            </div>
          ))}
          {isLoading && [...Array(3)].map((_, index) => <PersonCellSkeleton key={index} />)}
        </div>
        <div ref={scrollableRef} className={styles.scrollableCol}>
          {interpreters.map((interpreter, i) => {
            const meetings = serializeMeetings(interpreter.availability);
            return (
              <div
                key={`${interpreter.person.uid}_data_row_${i}`}
                className={styles.timelineGrid}
                style={{ gridTemplateColumns: `repeat(${gridHours + 1}, 10rem)` }}
              >
                {meetings.map((meeting) => {
                  return (
                    <TimeslotPill
                      key={`${meeting.start.valueOf()}_${meeting.end.valueOf()}_${
                        interpreter.person
                      }`}
                      variant={meeting.variant || 'default'}
                      position={getItemPosition(meeting.start, meeting.end)}
                      icon={meeting.icon}
                      title={meeting.title}
                    />
                  );
                })}
                {hoursArr.map((hour) => (
                  <div key={`${hour}_${interpreter.person.uid}`} className={styles.timelineCell} />
                ))}
              </div>
            );
          })}
          {isLoading &&
            [...Array(3)].map((_, index) => (
              <div
                className={styles.timelineGrid}
                style={{ gridTemplateColumns: `repeat(${gridHours}, 10rem)` }}
                key={index}
              >
                {hoursArr.map((hour) => (
                  <div key={`${hour}}`} className={styles.timelineCell} />
                ))}
              </div>
            ))}
          {selectedTimeslot && !isLoading && (
            <TimeslotPill
              ref={selectedTimeslotRef}
              position={getItemPosition(selectedTimeslot.start, selectedTimeslot.end)}
              variant="selected"
              {...selectedTimeslot}
            />
          )}
        </div>
      </div>
      {interpretersData && parseInt(filters.page) < interpretersData.pagination.pages && (
        <div className={styles.loadMoreButton}>
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              setFilters({ ...filters, page: (parseInt(filters.page) + 1).toString() });
            }}
            data-testid="load-more-button"
          >
            <FormattedMessage id={translationKeys.jobs_page_load_more_button} />
          </Button>
        </div>
      )}
    </div>
  );
}
