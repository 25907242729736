import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCircleDollar, faInfoCircle, faMap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, Button, Drawer, RadioOptions, Table, TextField } from '@skiwo/components';
import classNames from 'classnames';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobSessionType } from '../../../../types';
import { ManagerJobSuitableInterpreter } from '../../../../types/ManagerJobSuitableInterpreter';
import PersonDetails from '../PersonDetails';
import styles from './ApplyInterpreterDrawer.module.scss';

enum TravelMethodOptions {
  AlreadyAtLocation = 'already_at_location',
  PublicTransport = 'public_transport',
  Driving = 'driving',
}

interface ApplyInterpreterDrawerProps {
  show: boolean;
  onClose: () => void;
  interpreter: ManagerJobSuitableInterpreter;
  sessionType: ManagerJobSessionType;
}

export const ApplyInterpreterDrawer = ({
  show,
  onClose,
  interpreter,
  sessionType,
}: ApplyInterpreterDrawerProps) => {
  const intl = useIntl();
  const [selectedTravelMethod, setSelectedTravelMethod] = useState<TravelMethodOptions>(
    TravelMethodOptions.AlreadyAtLocation,
  );

  return (
    <Drawer
      show={show}
      title={intl.formatMessage({ id: translationKeys.job_apply_interpreter_drawer_label })}
      onClose={onClose}
    >
      <div className={styles.applyInterpreterDrawer}>
        <div>
          <PersonDetails className={styles.cardWrapper} interpreter={interpreter} />
        </div>
        {selectedTravelMethod === TravelMethodOptions.PublicTransport && (
          <Banner
            variant="information"
            text={intl.formatMessage(
              {
                id: translationKeys.job_apply_interpreter_drawer_public_transport_banner,
              },
              { amount: 200.0 },
            )}
          />
        )}
        {selectedTravelMethod === TravelMethodOptions.Driving && (
          <Banner
            variant="information"
            text={intl.formatMessage(
              {
                id: translationKeys.job_apply_interpreter_drawer_driving_banner,
              },
              { amount: 200.0 },
            )}
          />
        )}
        {sessionType === ManagerJobSessionType.InPerson && (
          <RadioOptions
            label={intl.formatMessage({
              id: translationKeys.job_apply_interpreter_drawer_travel_method,
            })}
            options={[
              {
                id: TravelMethodOptions.AlreadyAtLocation,
                title: intl.formatMessage({
                  id: translationKeys.job_apply_interpreter_drawer_already_at_location,
                }),
              },
              {
                id: TravelMethodOptions.PublicTransport,
                title: intl.formatMessage({
                  id: translationKeys.job_apply_interpreter_drawer_public_transport,
                }),
              },
              {
                id: TravelMethodOptions.Driving,
                title: intl.formatMessage({
                  id: translationKeys.job_apply_interpreter_drawer_driving,
                }),
              },
            ]}
            selected={selectedTravelMethod}
            onSelect={(selectedId) => setSelectedTravelMethod(selectedId as TravelMethodOptions)}
          />
        )}
        <span className={styles.tableTitle}>
          <FontAwesomeIcon icon={faCircleDollar} />
          <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_interpreter_payout} />
        </span>
        <Table striped>
          <thead>
            <tr>
              <th>
                <span className={styles.tableHeader}>
                  <FormattedMessage
                    id={translationKeys.job_apply_interpreter_drawer_services_column}
                  />
                </span>
              </th>
              <th>
                <span className={classNames(styles.tableHeader)}>
                  <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_rate_column} />
                </span>
              </th>
            </tr>
          </thead>
          <tbody className={styles.table}>
            <tr>
              <td>
                Robbert Smith/Rodney Boot: #379951 05.10.2024 10:00-11:00 Testspräk A Fremmate
                Kategori B - Rodney Boot Tolking
              </td>
              <td>
                <span className={styles.textRight}>100.00 NOK</span>
              </td>
            </tr>
            <tr>
              <td>
                Robbert Smith/Rodney Boot: #379951 05.10.2024 10:00-11:00 Testspräk A Fremmate
                Kategori B - Rodney Boot Tolking
              </td>
              <td>
                <span className={styles.textRight}>100.00 NOK</span>
              </td>
            </tr>
          </tbody>
        </Table>
        {sessionType === ManagerJobSessionType.InPerson && (
          <>
            <span className={styles.tableTitle}>
              <FontAwesomeIcon icon={faMap} />
              <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_travel} />
            </span>
            <Table striped>
              <thead>
                <tr>
                  <th>
                    <span className={styles.tableHeader}>
                      <FormattedMessage
                        id={translationKeys.job_apply_interpreter_drawer_services_column}
                      />
                    </span>
                  </th>
                  {selectedTravelMethod !== TravelMethodOptions.AlreadyAtLocation && (
                    <th>
                      <span className={classNames(styles.tableHeader)}>
                        <FormattedMessage
                          id={translationKeys.job_apply_interpreter_drawer_rate_column}
                        />
                      </span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className={styles.table}>
                <tr>
                  <td>
                    <div className={styles.travel}>
                      <span className={styles.travelLabel}>
                        <FormattedMessage
                          id={translationKeys.job_apply_interpreter_drawer_travel_from}
                        />
                      </span>
                      Storgata 2, 1767 Halden, Norway
                    </div>
                  </td>
                  {selectedTravelMethod !== TravelMethodOptions.AlreadyAtLocation && <td></td>}
                </tr>
                <tr>
                  <td>
                    <div className={styles.travel}>
                      <span className={styles.travelLabel}>
                        <FormattedMessage
                          id={translationKeys.job_apply_interpreter_drawer_travel_to}
                        />
                      </span>
                      Pilestredet 17, 0164 OSLO
                    </div>
                  </td>
                  {selectedTravelMethod !== TravelMethodOptions.AlreadyAtLocation && <td></td>}
                </tr>
                {selectedTravelMethod === TravelMethodOptions.PublicTransport && (
                  <>
                    <tr>
                      <td>
                        <FormattedMessage
                          id={translationKeys.job_apply_interpreter_drawer_travel_time}
                        />
                      </td>
                      <td>
                        <span className={classNames(styles.gridRow, styles.textRight)}>
                          <TextField name="travelTime" placeholder="0" textAlign="right" />
                          <span>hr</span>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <FormattedMessage
                          id={translationKeys.job_apply_interpreter_drawer_transport_tickets}
                        />
                      </td>
                      <td>
                        <span className={classNames(styles.gridRow, styles.textRight)}>
                          <TextField name="transportTickets" placeholder="0" textAlign="right" />
                          <span>NOK</span>
                        </span>
                      </td>
                    </tr>
                  </>
                )}

                {selectedTravelMethod === TravelMethodOptions.Driving && (
                  <>
                    <tr>
                      <td>
                        <div className={styles.travel}>
                          <span className={styles.travelLabel}>
                            <FormattedMessage
                              id={translationKeys.job_apply_interpreter_drawer_travel_time}
                            />
                          </span>
                          2 hour(s)
                        </div>
                      </td>
                      <td>
                        <span className={styles.textRight}>100.00 NOK</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className={styles.travel}>
                          <span className={styles.travelLabel}>
                            <FormattedMessage
                              id={translationKeys.job_apply_interpreter_drawer_transport_tickets}
                            />
                          </span>
                          <span>100 km</span>
                          <span
                            className={classNames(
                              styles.flexRow,
                              styles.travelLabel,
                              styles.warning,
                            )}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                            <FormattedMessage
                              id={translationKeys.job_apply_interpreter_drawer_max_payable_distance}
                              values={{ distance: 200 }}
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className={styles.textRight}>100.00 NOK</span>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </>
        )}
        <div className={styles.totalPriceRow}>
          <span>
            <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_total} />
          </span>
          <span className={classNames(styles.price, styles.textRight)}>200.00 NOK</span>
        </div>

        {selectedTravelMethod === TravelMethodOptions.Driving && (
          <Banner
            variant="information"
            text={intl.formatMessage({
              id: translationKeys.job_apply_interpreter_drawer_driving_customer_not_reimburse_banner,
            })}
          />
        )}
        <div className={styles.buttons}>
          <Button onClick={onClose} variant="gray" size="large">
            <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_cancel} />
          </Button>

          <Button
            data-testid="submit-button"
            iconPosition="left"
            size="large"
            variant="primary"
            type="submit"
          >
            <FormattedMessage id={translationKeys.job_apply_interpreter_drawer_apply} />
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
