import React from 'react';
import { useIntl } from 'react-intl';
import { Banner, Modal } from '@skiwo/components';
import { ApiError } from '../../../../Api';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobSuitableInterpreter } from '../../../../types/ManagerJobSuitableInterpreter';
import PersonDetails from '../PersonDetails';
import styles from './AwardInterpreterModal.module.scss';

export default function AwardInterpreterModal({
  interpreter,
  show,
  onClose,
}: {
  interpreter: ManagerJobSuitableInterpreter;
  show: boolean;
  onClose: () => void;
}) {
  const intl = useIntl();
  const { showErrorToast } = useToast();

  return (
    <Modal
      title={intl.formatMessage({ id: translationKeys.job_award_interpreter_modal_label })}
      description={intl.formatMessage({
        id: translationKeys.job_award_interpreter_modal_description,
      })}
      show={show}
      onHide={onClose}
      submitButtonText={intl.formatMessage({
        id: translationKeys.job_award_interpreter_modal_award,
      })}
      onCancel={onClose}
      onSubmit={async () => {
        try {
          // eslint-disable-next-line no-console
          console.log('Awarding interpreter');
        } catch (error) {
          showErrorToast(error as ApiError);
        } finally {
          onClose();
        }
      }}
    >
      <PersonDetails className={styles.cardWrapper} interpreter={interpreter} />
      <Banner
        variant="warning"
        text={intl.formatMessage({ id: translationKeys.job_award_interpreter_not_suitable })}
        className={styles.banner}
      />
    </Modal>
  );
}
