import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  faCircleDollar,
  faClock,
  faComment,
  faEnvelope,
  faHeart,
  faLocationDot,
  faPaperPlaneTop,
  faPhone,
  faVenusMars,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Button, Tag, Tooltip } from '@skiwo/components';
import { getFormattedAddress } from '@skiwo/utils';
import classNames from 'classnames';
import { format } from 'date-fns';
import QualificationLabel from '../../../components/QualificationLabel/QualificationLabel';
import NotesDrawer from '../../../Drawers/NotesDrawer/NotesDrawer';
import getGenderName from '../../../helpers/getGenderName';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobSuitableInterpreter } from '../../../types/ManagerJobSuitableInterpreter';
import {
  getReasonText,
  renderStatusTag,
  SuitabilityReason,
} from './helpers/InterpretersTimeline.helpers';
import styles from './InterpretersTimeline.module.scss';

export default function PersonDetails({
  interpreter,
  className,
  isAvatarActive,
}: {
  interpreter: ManagerJobSuitableInterpreter;
  className?: string;
  isAvatarActive?: boolean;
}) {
  const intl = useIntl();
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const reasons = Object.entries(interpreter.suitability)
    .map(([key, val]) => (val === false ? key : null))
    .filter(Boolean);

  return (
    <div className={classNames(styles.personWrapper, className)}>
      <Avatar
        url={interpreter.person.avatar}
        altText={interpreter.person.name}
        size="large"
        active={isAvatarActive}
      />
      <div className={styles.personColumn}>
        <div className={classNames(styles.personRow, styles.personHeader)}>
          {interpreter.person.phone && (
            <Tooltip title={interpreter.person.phone} copyable>
              <FontAwesomeIcon icon={faPhone} />
            </Tooltip>
          )}
          {interpreter.person.email && (
            <Tooltip title={interpreter.person.email} copyable>
              <FontAwesomeIcon icon={faEnvelope} />
            </Tooltip>
          )}
          <h4>{interpreter.person.name}</h4>
          {renderStatusTag(interpreter.suitabilityStatus, interpreter.discussion?.status, intl)}
          {interpreter.personNotesCount !== undefined && (
            <Button
              className={styles.notesBtn}
              variant="link"
              icon={<FontAwesomeIcon icon={faComment} />}
              onClick={() => setShowNotesDrawer(true)}
            >
              {interpreter.personNotesCount}
            </Button>
          )}
        </div>
        <div className={classNames(styles.personRow, styles['personRow--gap-l'])}>
          {interpreter.personAddress && (
            <Tooltip title={getFormattedAddress(interpreter.personAddress) ?? undefined}>
              <div>
                <FontAwesomeIcon icon={faLocationDot} />{' '}
                {[interpreter.personAddress.city, interpreter.personAddress.country]
                  .filter(Boolean)
                  .join(', ')}
              </div>
            </Tooltip>
          )}
          {/* {person.distance && (
            <div>
              <FontAwesomeIcon icon={faArrowsLeftRight} /> {person.distance} km
            </div>
          )} */}
          {interpreter.person.gender && (
            <div>
              <FontAwesomeIcon icon={faVenusMars} />{' '}
              {getGenderName(interpreter.person.gender, intl)}
            </div>
          )}
          {interpreter.mainSkill && (
            <QualificationLabel
              category={interpreter.mainSkill.qualification.tolkeregisterName || 'N/A'}
              language={interpreter.mainSkill.targetLanguage.name}
            />
          )}
          {interpreter.alternativeSkill && (
            <QualificationLabel
              category={interpreter.alternativeSkill.qualification.tolkeregisterName || 'N/A'}
              language={interpreter.alternativeSkill.targetLanguage.name}
            />
          )}
        </div>
        <div className={classNames(styles.personRow, styles['personRow--gap-l'])}>
          {interpreter.hourlyRate && (
            <Tooltip
              title={intl.formatMessage(
                {
                  id: translationKeys.job_interpreters_timeline_hourly_rate_label,
                },
                {
                  rate: interpreter.hourlyRate,
                },
              )}
            >
              <Tag color="neutral">
                <FontAwesomeIcon icon={faCircleDollar} /> {interpreter.hourlyRate}/hr
              </Tag>
            </Tooltip>
          )}
          {interpreter.isFavorite && (
            <Tag color="neutral">
              <FontAwesomeIcon icon={faHeart} />{' '}
              {intl.formatMessage({
                id: translationKeys.job_interpreters_timeline_favourite_label,
              })}
            </Tag>
          )}
          {interpreter.discussion && interpreter.discussion.lastInvitedAt && (
            <Tooltip
              title={intl.formatMessage(
                { id: translationKeys.job_interpreters_timeline_last_invited_label },
                {
                  date: format(new Date(interpreter.discussion.lastInvitedAt), 'dd.MM.yy, HH:mm'),
                  via: interpreter.discussion.lastInvitedVia,
                },
              )}
            >
              <Tag color="neutral">
                <FontAwesomeIcon icon={faClock} />{' '}
                {format(new Date(interpreter.discussion.lastInvitedAt), 'd MMM yyy')}
              </Tag>
            </Tooltip>
          )}
          {reasons.length ? (
            reasons.length <= 1 ? (
              <Tag color="neutral" key={reasons[0]}>
                <FontAwesomeIcon icon={faComment} />{' '}
                {getReasonText(reasons[0] as SuitabilityReason, intl)}
              </Tag>
            ) : (
              <Tooltip
                list={
                  !!reasons.length &&
                  reasons.map((reason) => (
                    <div key={reason}>{getReasonText(reason as SuitabilityReason, intl)}</div>
                  ))
                }
              >
                <Tag color="neutral" key={reasons[0]}>
                  <FontAwesomeIcon icon={faComment} />{' '}
                  {getReasonText(reasons[0] as SuitabilityReason, intl)} +{reasons.length - 1}
                </Tag>
              </Tooltip>
            )
          ) : null}

          {interpreter.discussion && (
            <Tooltip
              title={intl.formatMessage(
                { id: translationKeys.job_interpreters_timeline_invites_sent_label },
                { count: interpreter.discussion.invitationsCount },
              )}
            >
              <Tag color="neutral">
                <FontAwesomeIcon icon={faPaperPlaneTop} /> {interpreter.discussion.invitationsCount}
              </Tag>
            </Tooltip>
          )}
        </div>
      </div>
      <NotesDrawer
        show={showNotesDrawer}
        onClose={() => setShowNotesDrawer(false)}
        onCancel={() => setShowNotesDrawer(false)}
        user={{
          id: interpreter.person.id,
          name: interpreter.person.name,
          email: interpreter.person.email,
          phone: interpreter.person.phone,
          uid: interpreter.person.uid,
        }}
      />
    </div>
  );
}
