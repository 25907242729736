import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Drawer,
  RadioOption,
  RadioOptions,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import classNames from 'classnames';
import { Formik } from 'formik';
import * as yup from 'yup';
import CreatePaymentDrawer from '../../../../components/CreatePaymentDrawer/CreatePaymentDrawer';
import getRequiredMessage from '../../../../helpers/getRequiredFieldMessage';
import { useApi } from '../../../../providers/ApiProvider';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { CustomerPaymentMethod, ManagerOrder } from '../../../../types';
import { translationOrderErrorKeys } from '../../../utils/translationOrderErrorKeys';
import styles from './EditInvoicing.module.scss';

interface Props {
  show?: boolean;
  order: ManagerOrder;
  onClose: () => void;
}

interface FormValues {
  selectedPaymentMethodId: string;
  bookerRef: string;
  invoiceRef: string;
  caseNumber: string;
  clientNumber: string;
  clientName: string;
}

const EditInvoicing = (props: Props) => {
  const { show, order, onClose } = props;
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<CustomerPaymentMethod>();
  const intl = useIntl();
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<RadioOption[]>([]);
  const [paymentMethodDropdownOptions, setPaymentMethodDropdownOptions] = useState<
    SearchDropdownMenuOption[]
  >([]);
  const [paymentMethods, setPaymentMethods] = useState<CustomerPaymentMethod[]>([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const api = useApi();
  const { showErrorToast } = useToast();
  const schema = yup.object().shape({
    bookerRef:
      selectedPaymentMethod?.allowBookingReference && selectedPaymentMethod?.requireBookingReference
        ? yup
            .string()
            .matches(
              /^[0-9a-zA-ZÆØÅæøå\\/:\- ]*$/,
              intl.formatMessage({ id: translationKeys.wrong_reference }),
            )
            .required(getRequiredMessage(intl, selectedPaymentMethod.labelForBookingReference))
        : yup
            .string()
            .matches(
              /^[0-9a-zA-ZÆØÅæøå\\/:\- ]*$/,
              intl.formatMessage({ id: translationKeys.wrong_reference }),
            )
            .notRequired(),
    invoiceRef:
      selectedPaymentMethod?.allowPaymentBookingReference &&
      selectedPaymentMethod?.requirePaymentBookingReference
        ? yup
            .string()
            .matches(
              /^[0-9a-zA-ZÆØÅæøå\\/:\- ]*$/,
              intl.formatMessage({ id: translationKeys.wrong_reference }),
            )
            .required(
              getRequiredMessage(intl, selectedPaymentMethod.labelForPaymentBookingReference),
            )
        : yup
            .string()
            .matches(
              /^[0-9a-zA-ZÆØÅæøå\\/:\- ]*$/,
              intl.formatMessage({ id: translationKeys.wrong_reference }),
            )
            .notRequired(),
    caseNumber:
      selectedPaymentMethod?.allowCaseNumber && selectedPaymentMethod?.requireCaseNumber
        ? yup.string().required(getRequiredMessage(intl, selectedPaymentMethod.labelForCaseNumber))
        : yup.string().notRequired(),
    clientNumber: yup.string(),
    clientName: yup.string(),
  });
  const [showCreateDrawer, setShowCreateDrawer] = useState(false);

  const handleSelectPaymentMethod = (selectedId: string) => {
    const selectedMethod = paymentMethods.find((method) => method.id.toString() === selectedId);

    if (selectedMethod) {
      setSelectedPaymentMethod(selectedMethod);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    const updateValue = {
      requirementIdempotencyKey: order.requirementIdempotencyKey,
      paymentMethodId: selectedPaymentMethod?.id.toString(),
      bookingReference: values.bookerRef,
      paymentBookingReference: values.invoiceRef,
      caseNumber: values.caseNumber,
      clientId: values.clientNumber,
      clientName: values.clientName,
    } as Partial<ManagerOrder>;

    const { data, error } = await api.updateOrder(
      order.id.toString(),
      updateValue,
      setUpdateLoading,
    );

    if (data) {
      onClose();
    }

    if (error) {
      showErrorToast(error, translationOrderErrorKeys);
    }
  };

  const getPaymentMethods = async () => {
    const { data, error } = await api.getPaymentMethods({
      customerUid: order.owner?.uid,
    });

    if (data) {
      setPaymentMethods(data);

      const paymentOptions: RadioOption[] = data
        .map((option) => {
          const title = option.orgNumber ? option.orgName : option.email;
          return {
            id: option.id.toString(),
            title,
            subtitle:
              option.orgNumber ||
              intl.formatMessage({
                id: translationKeys.translation_order_edit_invoicing_payment_methods_email_label,
              }),
            default: option.default,
          };
        })
        .sort((a, b) => {
          return b.default ? 1 : a.default ? -1 : 0;
        });

      const paymentDropdownOptions: SearchDropdownMenuOption[] = data.map((option) => {
        const title = option.orgNumber
          ? `${option.orgName || '-'} / ${option.orgNumber}`
          : `${option.email || '-'} / ${intl.formatMessage({
              id: translationKeys.translation_order_edit_invoicing_payment_methods_email_label,
            })}`;
        return {
          id: option.id,
          label: title,
          key: option.id.toString(),
        };
      });

      setPaymentMethodOptions(paymentOptions);
      setPaymentMethodDropdownOptions(paymentDropdownOptions);

      if (order.paymentMethodId) {
        const selectedMethod = data.find(
          (method) => method.id.toString() === order.paymentMethodId?.toString(),
        );

        if (selectedMethod) {
          setSelectedPaymentMethod(selectedMethod);
        }
      }
    }

    if (error) {
      showErrorToast(error);
    }
  };

  useEffect(() => {
    if (!show) return;

    getPaymentMethods();
  }, [show]);

  const getSelectedPaymentMethod = (id: string) => {
    const option = paymentMethodDropdownOptions.find((option) => {
      return option.id.toString() === id;
    });

    return option ? [option] : [];
  };

  return (
    <>
      <Drawer
        show={show}
        onClose={onClose}
        title={intl.formatMessage({ id: translationKeys.translation_order_edit_invoicing_title })}
        data-testid="edit-invoicing-drawer"
      >
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            selectedPaymentMethodId: order.paymentMethodId?.toString() || '',
            bookerRef: order.bookingReference || '',
            invoiceRef: order.paymentBookingReference || '',
            caseNumber: order.caseNumber || '',
            clientNumber: order.clientId || '',
            clientName: order.clientName || '',
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, errors }) => {
            const getBookerRefDecoration = () => {
              if (!selectedPaymentMethod?.defaultBookingReference) return <></>;

              if (values.bookerRef === selectedPaymentMethod?.defaultBookingReference) {
                return (
                  <span className={styles.defaultIndicator}>
                    <FormattedMessage
                      id={translationKeys.translation_order_edit_invoicing_default_label}
                    />
                  </span>
                );
              } else {
                return (
                  <FontAwesomeIcon
                    icon={faRotateLeft}
                    onClick={() =>
                      setFieldValue('bookerRef', selectedPaymentMethod?.defaultBookingReference)
                    }
                  />
                );
              }
            };

            const getInvoiceRefDecoration = () => {
              if (!selectedPaymentMethod?.defaultBookingReference) return <></>;

              if (values.invoiceRef === selectedPaymentMethod?.defaultPaymentBookingReference) {
                return (
                  <span className={styles.defaultIndicator}>
                    <FormattedMessage
                      id={translationKeys.translation_order_edit_invoicing_default_label}
                    />
                  </span>
                );
              } else {
                return (
                  <FontAwesomeIcon
                    icon={faRotateLeft}
                    onClick={() =>
                      setFieldValue(
                        'invoiceRef',
                        selectedPaymentMethod?.defaultPaymentBookingReference,
                      )
                    }
                  />
                );
              }
            };

            return (
              <Form className={styles.editInvoicing} onSubmit={handleSubmit}>
                {!paymentMethodOptions.length ? (
                  <div>
                    <span className={styles.label}>
                      <FormattedMessage
                        id={translationKeys.translation_order_edit_invoicing_payment_methods_label}
                      />
                    </span>
                    <button
                      className={classNames(styles.addNewButton)}
                      onClick={() => setShowCreateDrawer(true)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      <FormattedMessage
                        id={translationKeys.create_translation_order_invoicing_add_new_button}
                      />
                    </button>
                  </div>
                ) : (
                  <>
                    {paymentMethodOptions.length <= 2 && (
                      <div className={styles.paymentMethods}>
                        <RadioOptions
                          label={intl.formatMessage({
                            id: translationKeys.translation_order_edit_invoicing_payment_methods_label,
                          })}
                          options={paymentMethodOptions}
                          selected={
                            order.paymentMethodId ? order.paymentMethodId.toString() : undefined
                          }
                          onSelect={(selectedId) => handleSelectPaymentMethod(selectedId)}
                          block
                        />
                        <button
                          className={styles.newOption}
                          onClick={() => setShowCreateDrawer(true)}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                          <FormattedMessage
                            id={translationKeys.create_translation_order_invoicing_add_new_button}
                          />
                        </button>
                      </div>
                    )}
                    {paymentMethodOptions.length > 2 && (
                      <div className={styles.dropdownPaymentMethods}>
                        <B_Form.Label>
                          <FormattedMessage
                            id={
                              translationKeys.translation_order_edit_invoicing_payment_methods_label
                            }
                          />
                        </B_Form.Label>
                        <div className={styles.selectorWithButton}>
                          <div className={styles.selector}>
                            <SearchDropdown
                              options={paymentMethodDropdownOptions}
                              selected={getSelectedPaymentMethod(values.selectedPaymentMethodId)}
                              onChange={(option) => {
                                if (option && option.length > 0 && option[0].key) {
                                  setFieldValue('selectedPaymentMethodId', option[0].id.toString());
                                  handleSelectPaymentMethod(option[0].id.toString());
                                }
                              }}
                              size="large"
                            />
                          </div>
                          <Button
                            variant="gray"
                            icon={<FontAwesomeIcon icon={faPlus} />}
                            onClick={() => setShowCreateDrawer(true)}
                            size="x-large"
                            className={styles.button}
                          >
                            <FormattedMessage
                              id={translationKeys.create_translation_order_invoicing_add_new_button}
                            />
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <hr />
                <div className={styles.inputRow}>
                  <TextField
                    label={selectedPaymentMethod?.labelForBookingReference || intl.formatMessage({ id: translationKeys.booking_reference_default_label })}
                    placeholder={`${selectedPaymentMethod?.labelForBookingReference || intl.formatMessage({ id: translationKeys.booking_reference_default_label })}...`}
                    size="large"
                    hint={selectedPaymentMethod?.bookingReferenceRegexHint || ''}
                    decorationView={getBookerRefDecoration()}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errorText={touched.bookerRef ? errors.bookerRef : undefined}
                    value={values.bookerRef}
                    name="bookerRef"
                  />
                  <TextField
                    label={selectedPaymentMethod?.labelForPaymentBookingReference || intl.formatMessage({ id: translationKeys.payment_booking_reference_default_label })}
                    placeholder={`${selectedPaymentMethod?.labelForPaymentBookingReference || intl.formatMessage({ id: translationKeys.payment_booking_reference_default_label })}...`}
                    size="large"
                    hint={selectedPaymentMethod?.paymentBookingReferenceRegexHint || ''}
                    decorationView={getInvoiceRefDecoration()}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errorText={touched.invoiceRef ? errors.invoiceRef : undefined}
                    value={values.invoiceRef}
                    name="invoiceRef"
                  />
                </div>
                {selectedPaymentMethod?.allowCaseNumber && (
                  <TextField
                    label={selectedPaymentMethod?.labelForCaseNumber || intl.formatMessage({ id: translationKeys.case_number_default_label })}
                    placeholder={`${selectedPaymentMethod?.labelForCaseNumber || intl.formatMessage({ id: translationKeys.case_number_default_label })}...`}
                    size="large"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errorText={touched.caseNumber ? errors.caseNumber : undefined}
                    value={values.caseNumber}
                    name="caseNumber"
                  />
                )}
                {selectedPaymentMethod?.allowClientId && (
                  <TextField
                    label={intl.formatMessage({
                      id: translationKeys.translation_order_edit_invoicing_client_number_label,
                    })}
                    placeholder={intl.formatMessage({
                      id: translationKeys.translation_order_edit_invoicing_client_number_placeholder,
                    })}
                    size="large"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errorText={touched.clientNumber ? errors.clientNumber : undefined}
                    value={values.clientNumber}
                    name="clientNumber"
                  />
                )}
                {selectedPaymentMethod?.allowClientName && (
                  <TextField
                    label={intl.formatMessage({
                      id: translationKeys.translation_order_edit_invoicing_client_name_label,
                    })}
                    placeholder={intl.formatMessage({
                      id: translationKeys.translation_order_edit_invoicing_client_name_placeholder,
                    })}
                    size="large"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    errorText={touched.clientName ? errors.clientName : undefined}
                    value={values.clientName}
                    name="clientName"
                  />
                )}

                <div className={styles.actions}>
                  <Button variant="gray" size="large" onClick={onClose}>
                    <FormattedMessage
                      id={translationKeys.translation_order_edit_invoicing_cancel_button}
                    />
                  </Button>
                  <Button type="submit" size="large" isLoading={updateLoading}>
                    <FormattedMessage
                      id={translationKeys.translation_order_edit_invoicing_save_button}
                    />
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Drawer>

      <CreatePaymentDrawer
        show={showCreateDrawer}
        onClose={() => {
          setShowCreateDrawer(false);
          getPaymentMethods();
        }}
        customerName={order.owner?.name}
        enterpriseId={order.enterpriseId?.toString()}
        enterpriseName={order.enterprise?.name}
      />
    </>
  );
};

export default EditInvoicing;
