import Enterprise from './Enterprise';
import { ManagerJobSessionType, ManagerJobStatus } from './ManagerJob';

export enum ManagerCustomerType {
  Enterprise = 'enterprise',
  Private = 'private',
  Undefined = 'undefined',
}

export enum ManagerCustomerRole {
  Admin = 'admin',
  Finance = 'finance',
  Booker = 'booker',
  Attender = 'attender',
  Organizer = 'organizer',
}

export enum ManagerCustomerAccountStatus {
  Active = 'active',
  Paused = 'paused',
  Blocked = 'blocked',
  Retired = 'retired',
  Banned = 'banned',
  Deleted = 'deleted',
  Deceased = 'deceased',
  Inactive = 'inactive',
}

interface ManagerCustomer {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  emailSuppressed: boolean;
  emailVerified: boolean;
  emailVerificationSentAt: string;
  phone?: string;
  phoneCode?: string;
  phoneNumber?: string;
  type: ManagerCustomerType;
  uid: string;
  demanderId?: string;
  isShared: boolean;
  enterprise?: Enterprise;
  defaultBookingReference?: string;
  defaultPaymentBookingReference?: string;
  departments?: {
    id: number;
    name: string;
    defaultBookingReference?: string;
    defaultPaymentBookingReference?: string;
  }[];
  hasFutureAssignments: boolean;
  hasOngoingAssignment: boolean;
  roles?: ManagerCustomerRole[];
  enterpriseMembershipId?: number;
  godmodeDemanderUrl: string;
  bookingReference: string;
  paymentBookingReference: string;
  seriesOrderEnabled: boolean;
  nextJob?: {
    id: number;
    startTime: string;
    godmodeDemanderUrl: string;
    godmodeSupplierUrl?: string;
  };
  accountStatus: {
    name: ManagerCustomerAccountStatus;
    expiresAt: null;
    comment: null;
  } | null;
}

export interface ManagerCustomerDetails {
  id: number;
  uid: string;
  demanderId: number;
  name: string;
  firstName: string;
  lastName: string;
  isShared: boolean;
  email: string;
  emailVerified: boolean;
  emailSuppressed: boolean;
  emailSuppressionRemovedAt?: string;
  emailSuppressionRemovedBy?: string;
  defaultBookingReference?: string; 
  defaultPaymentBookingReference?: string; 
  enterpriseMembershipId?: number;
  hasFutureAssignments: boolean;
  hasOngoingAssignments: boolean;
  phoneNumberVerified: boolean;
  type: ManagerCustomerType;
  avatar: string;
  phoneNumber?: string;
  phoneCode?: string;
  phone?: string;
  roles?: ManagerCustomerRole[];
  departments?: {
    id: number;
    name: string;
  }[];
  enterprise: {
    id: number;
    name?: string;
    orgNumber: string;
    logo: string;
    website?: string;
    address: {
      line_1: string;
      line_2: string;
      postcode: string;
      city: string;
      country: string;
      county: string;
    };
    owner: {
      fullName: string;
      email: string;
      phoneNumber: string;
    };
    whitelistedDomains: string[];
    defaultBookingReference: string | null; 
    defaultPaymentBookingReference: string | null; 
  };
  godmodeDemanderUrl: string;
  statistics: {
    totalJobs?: number;
    accountCreated?: string;
    lastPostedJob?: string;
    lastFinishedJob?: string;
    upcomingJob: {
      id: number;
      startTime: string;
      finishTime: string;
      languageTo: string;
      sessionType: ManagerJobSessionType;
      status: ManagerJobStatus;
      phoneNumber: string | null;
    } | null;
  };
  accountStatus: {
    name: string;
    expiresAt?: string;
    comment?: string;
  };
}

export interface ManagerCustomersResponse {
  customers: ManagerCustomer[];
  statistics: {
    active: number;
    all: number;
    inactive: number;
  };
  count: number;
  page: number;
  pages: number;
}
export interface ManagerCustomerDetailsResponse {
  customer: ManagerCustomerDetails;
}

export default ManagerCustomer;
