import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import {
  faChevronDown,
  faChevronUp,
  faClock,
  faUser,
  faUserGroup,
  faWandMagicSparkles,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownItem, Tooltip } from '@skiwo/components';
import { useGetJobQuery, useUpdateJobMutation } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import {
  getAssignmentTypeIcon,
  getAssignmentTypeLabel,
} from '../../../CreateInterpretationOrder/utils';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import { ManagerJobStandbyProcessing } from '../../../types';
import {
  ManagerJobAdminAutoInviteProcessing,
  ManagerJobStatus,
} from '../../../types/ManagerJob';
import useGetJobIdFromParam from '../../hooks/useGetJobIdFromParam';
import InfoItem from '../InfoItem/InfoItem';
import InterpretersTimeline from '../InterpretersTimeline/InterpretersTimeline';
import {
  getAutoInviteProps,
  getFormattedDate,
  getTimeDropdownItem,
} from './InterpretersSection.helpers';
import styles from './InterpretersSection.module.scss';

const DEFAULT_ITEM_ID = ''; // BE accepts NULL or empty string.

export default function InterpretersSection() {
  const intl = useIntl();
  const { showErrorToast } = useToast();
  const jobId = useGetJobIdFromParam();
  const { data: job, isFetching } = useGetJobQuery(jobId);
  const updateJob = useUpdateJobMutation();
  const [showInterpreters, setShowInterpreters] = useState(
    job?.status === ManagerJobStatus.Published || job?.status === ManagerJobStatus.Applied,
  );

  if (!job) return null;

  const dropdownItems: DropdownItem[] = [
    {
      id: DEFAULT_ITEM_ID,
      text: intl.formatMessage({
        id: translationKeys.job_interpreters_section_dropdown_field_magic_label,
      }),
    },
    ...[2, 5, 10, 20, 60, 120].map(getTimeDropdownItem(intl)),
  ];

  const selectedDropdownItem =
    job.processRequirement.invitationDelay?.toString() || DEFAULT_ITEM_ID;

  const invitations = job.interpreterInvitations;
  const isJobUpdating = updateJob.isPending || isFetching;
  const autoInviteTooltipProps = getAutoInviteProps(job.checkAutoInviteStatus, intl);

  return (
    <div className={styles.interpretersSection}>
      <div className={styles.header}>
        <h3 className={styles.title}>
          <FontAwesomeIcon icon={faUser} />
          {intl.formatMessage({
            id: translationKeys.job_interpreters_section_title,
          })}
        </h3>
        <B_Form.Check
          type="switch"
          label={intl.formatMessage({id: translationKeys.job_interpreters_section_show_interpreters_toggle})}
          checked={showInterpreters}
          onClick={() => {
            setShowInterpreters(!showInterpreters);
          }}
        />
      </div>
      {!!invitations && (
        <div className={styles.infoRow}>
          <InfoItem
            label={intl.formatMessage({
              id: translationKeys.job_interpreters_next_invite_label,
            })}
            icons={<FontAwesomeIcon icon={faClock} />}
          >
            {invitations.nextInvitationTime && getFormattedDate(invitations.nextInvitationTime)}
          </InfoItem>
          <InfoItem
            label={intl.formatMessage({
              id: translationKeys.job_interpreters_invited_so_far_label,
            })}
            icons={<FontAwesomeIcon icon={faUserGroup} />}
          >
            {`${invitations.alreadyInvited}/${invitations.suitableForInvitation}`}
          </InfoItem>
          <InfoItem
            label={intl.formatMessage({
              id: translationKeys.job_interpreters_total_invited_label,
            })}
            icons={<FontAwesomeIcon icon={faUserGroup} />}
          >
            {invitations.totalSuitableInterpreters}
          </InfoItem>
          <InfoItem
            label={intl.formatMessage({
              id: translationKeys.job_interpreters_last_invited_label,
            })}
            icons={<FontAwesomeIcon icon={faUser} />}
          >
            {invitations.lastInvitedInterpreter.name &&
              invitations.lastInvitedInterpreter.invitedAt &&
              `${invitations.lastInvitedInterpreter.name} • ${getFormattedDate(
                invitations.lastInvitedInterpreter.invitedAt,
              )}`}
          </InfoItem>
        </div>
      )}
      <div className={styles.toggleRow}>
        <B_Form.Check
          id="autoAward"
          data-testid="auto-award-switch"
          type="switch"
          disabled={isJobUpdating}
          checked={job.allowAutoAward}
          onChange={(e) => {
            const formData = new FormData();
            formData.append('allowAutoAward', e.target.checked.toString());

            updateJob.mutate(
              { id: job.id, body: formData },
              {
                onError: (error) => {
                  showErrorToast(error);
                },
              },
            );
          }}
          label={intl.formatMessage({
            id: translationKeys.job_interpreters_section_auto_award_label,
          })}
        />
        <B_Form.Check
          id="standbyProcessing"
          data-testid="standby-processing-switch"
          type="switch"
          disabled={isJobUpdating}
          checked={
            job.processRequirement.standbyProcessing === ManagerJobStandbyProcessing.TurnedOn
          }
          onChange={(e) => {
            const status = e.target.checked
              ? ManagerJobStandbyProcessing.TurnedOn
              : ManagerJobStandbyProcessing.TurnedOff;

            const formData = new FormData();
            formData.append('processRequirement[standbyProcessing]', status);

            updateJob.mutate(
              { id: job.id, body: formData },
              {
                onError: (error) => {
                  showErrorToast(error);
                },
              },
            );
          }}
          label={intl.formatMessage({
            id: translationKeys.job_interpreters_section_standby_label,
          })}
        />
        <ComponentWithTooltip tooltipText={autoInviteTooltipProps?.tooltip}>
          <B_Form.Check
            id="autoInvite"
            data-testid="auto-invite-switch"
            type="switch"
            disabled={isJobUpdating}
            checked={job.autoInviteEnabled}
            onChange={(e) => {
              const formData = new FormData();
              formData.append(
                'processRequirement[adminAutoInviteProcessing]',
                e.target.checked
                  ? ManagerJobAdminAutoInviteProcessing.AdminAutoInviteStart
                  : ManagerJobAdminAutoInviteProcessing.AdminAutoInviteStop,
              );

              updateJob.mutate(
                { id: job.id, body: formData },
                {
                  onError: (error) => {
                    showErrorToast(error);
                  },
                },
              );
            }}
            label={
              <span className={styles.switchLabel}>
                {intl.formatMessage({
                  id: translationKeys.job_interpreters_section_auto_invite_label,
                })}
                {autoInviteTooltipProps?.icon}
              </span>
            }
          />
        </ComponentWithTooltip>
        <Dropdown
          items={dropdownItems}
          selectedItemId={selectedDropdownItem}
          disabled={isJobUpdating}
          onSelect={(id) => {
            const formData = new FormData();
            formData.append('processRequirement[invitationDelay]', id ?? DEFAULT_ITEM_ID);

            updateJob.mutate(
              { id: job.id, body: formData },
              {
                onError: (error) => {
                  showErrorToast(error);
                },
              },
            );
          }}
          toggle={(isOpened) => (
            <button className={styles.dropdownBtn} type="button">
              <FontAwesomeIcon icon={faWandMagicSparkles} className={styles.btnIconLeft} />
              {dropdownItems.find((item) => item.id === selectedDropdownItem)?.text}
              <FontAwesomeIcon
                icon={isOpened ? faChevronUp : faChevronDown}
                className={styles.btnIconRight}
              />
            </button>
          )}
          hideClearSelected
        />
      </div>
      {showInterpreters && (
        <InterpretersTimeline
          jobId={job.id.toString()}
          selectedTimeslot={{
            start: new Date(job.interpretationRequirement.startTime),
            end: new Date(job.interpretationRequirement.finishTime),
            icon: (
              <FontAwesomeIcon
                icon={getAssignmentTypeIcon(job.interpretationRequirement.sessionType)}
              />
            ),
            title: getAssignmentTypeLabel(job.interpretationRequirement.sessionType, intl),
          }}
        />
      )}
    </div>
  );
}

function ComponentWithTooltip({
  children,
  tooltipText,
}: {
  tooltipText?: string;
  children: JSX.Element;
}) {
  if (!tooltipText) {
    return <>{children}</>;
  }

  return <Tooltip title={tooltipText}>{children}</Tooltip>;
}
