import { IntlShape } from 'react-intl';
import { FileState } from '@skiwo/components';
import { SearchDropdownMenuOption } from '@skiwo/components/src/SearchDropdown/SearchDropdown';
import { TimePickerItem } from '@skiwo/components/src/TimePicker/TimePicker';
import * as yup from 'yup';
import getRequiredMessage from '../helpers/getRequiredFieldMessage';
import translationKeys from '../translations/translationKeys';
import {
  CustomerPaymentMethod,
  ManagerJobBookingMechanism,
  ManagerJobDirectProcessing,
  ManagerJobGender,
  ManagerJobGenderProcessing,
  ManagerJobQualificationProcessing,
  ManagerJobSessionType,
} from '../types';
import { BaseContactAddress } from '../types/EnterpriseSearchResult';
import { Interpreter } from '../types/Interpreter';
import { ManagerJobBlockedCity } from '../types/ManagerJob';
import { AssignmentInPersonAlternative } from './AssignmentTypeSection/AssignmentTypeInPersonSection/AssignmentTypeInPersonSection';
import {
  AssignmentVideoAlternative,
  AssignmentVideoSolution,
} from './AssignmentTypeSection/AssignmentTypeVideoSection/AssignmentTypeVideoSection';
import { InterpreterMatchOptions } from './SettingsSection/SpecificIntepreterSettingsItem';
import { addTimeToDate } from './utils';

export enum AddressType {
  Google = 'google',
  Presaved = 'presaved',
}

export interface CreateInterpretationOrderFormValues {
  alternativeLanguageTo?: SearchDropdownMenuOption;
  assignmentInPersonAlternative?: AssignmentInPersonAlternative;
  assignmentVideoAlternative?: AssignmentVideoAlternative;
  assignmentVideoSolution?: AssignmentVideoSolution;
  bookingThresholdConfirmation: boolean;
  contactPersonAddress?: SearchDropdownMenuOption<BaseContactAddress>;
  contactPersonAddressType?: AddressType;
  contactPersonCountryCode?: SearchDropdownMenuOption;
  contactPersonDirections?: string;
  contactPersonName?: string;
  contactPersonPhoneNumber?: string;
  dates: {
    date: Date;
    startTime?: TimePickerItem;
    finishTime?: TimePickerItem;
  }[];
  departmentId: string;
  dialect: string;
  isBookingThresholdConfirmationRequired: boolean;
  jobCategory?: SearchDropdownMenuOption;
  languageId: string;
  sessionType: ManagerJobSessionType;
  specificQualification?: SearchDropdownMenuOption;
  subject: string;
  videoPlatform?: SearchDropdownMenuOption;
  videoPlatformDataRequired: boolean;
  videoPlatformInstructions: string;
  videoPlatformUrl: string;
  actualCreatedAtDate: Date;
  actualCreatedAtTime: TimePickerItem;
  additionalCustomerUids?: string[];
  alternativeLanguageActive: boolean;
  attachments: FileState[];
  blockCitiesActive: boolean;
  blockInterpretersActive: boolean;
  blockedCities?: SearchDropdownMenuOption<ManagerJobBlockedCity>[];
  blockedInterpreters?: SearchDropdownMenuOption[];
  bookingMechanism: ManagerJobBookingMechanism;
  bookingReference: string;
  bookerName?: string;
  caseNumber: string;
  ccActive: boolean;
  ccEmails: string[];
  confirmationByDate?: Date;
  confirmationByTime?: TimePickerItem;
  confirmationPhones: string[];
  customerId: string;
  deadlineConfirmationActive?: boolean;
  genderProcessing?: ManagerJobGenderProcessing;
  manualBookingAutoAward: boolean;
  manualBookingAutoInvite: boolean;
  manualBookingFeeApplied?: boolean;
  manualBookingStandBy: boolean;
  manualEditingFeeApplied?: boolean;
  manualTravelBookingFeeApplied?: boolean;
  matchInterpreterWith?: InterpreterMatchOptions;
  messageToInterpreter?: string;
  messageToInterpreterActive: boolean;
  paymentBookingReference: string;
  paymentMethodId: string;
  qualificationLevelActive: boolean;
  qualificationProcessing?: ManagerJobQualificationProcessing;
  saveAddress: boolean;
  specificGender?: ManagerJobGender;
  specificGenderActive: boolean;
  specificInterpreter?: SearchDropdownMenuOption<Interpreter>;
  specificInterpreterActive: boolean;
  specificInterpreterProcessing?: ManagerJobDirectProcessing;
  uploadFilesActive: boolean;
}

interface CreateInterpretationOrderSchemaProps {
  customerIsShared?: boolean;
  intl: IntlShape;
  paymentMethod?: CustomerPaymentMethod;
  isCategoryRequired: boolean;
  isCategorySubjectRequired: boolean;
}

export const customerSchema = ({
  intl,
  customerIsShared,
}: {
  intl: IntlShape;
  customerIsShared?: boolean;
}) =>
  yup.object().shape({
    ccActive: yup.boolean().default(false),
    confirmationPhones: yup.array().of(yup.string()).required(),
    ccEmails: yup
      .array()
      .when(
        ['ccActive, confirmationPhones'],
        ([ccActive, confirmationPhones]: string[] | null[], sch) =>
          ccActive
            ? confirmationPhones && confirmationPhones.length > 0
              ? sch.notRequired()
              : sch.min(1).required()
            : sch.notRequired(),
      ),
    bookerName: customerIsShared
      ? yup.string().required(
          getRequiredMessage(
            intl,
            intl.formatMessage({
              id: translationKeys.create_interpretation_order_customer_booker_name_placeholder,
            }),
          ),
        )
      : yup.string().notRequired(),
    additionalCustomerUids: yup.array().of(yup.string().required()),
  });

export const createInterpretationOrderSchema = ({
  customerIsShared,
  intl,
  paymentMethod,
  isCategoryRequired,
  isCategorySubjectRequired,
}: CreateInterpretationOrderSchemaProps) => {
  const customerSchemaInstance = customerSchema({ intl, customerIsShared });

  return yup
    .object()
    .shape({
      customerId: yup.string().required(),
      departmentId: yup.string().required(
        getRequiredMessage(
          intl,
          intl.formatMessage({
            id: translationKeys.create_interpretation_order_customer_department_label,
          }),
        ),
      ),
      additionalCustomers: yup.array().of(yup.string()).notRequired(),
      paymentMethodId: yup.string().required(),
      bookingReference:
        paymentMethod?.allowBookingReference && paymentMethod?.requireBookingReference
          ? yup.string().required(getRequiredMessage(intl, paymentMethod.labelForBookingReference))
          : yup.string().notRequired(),
      paymentBookingReference:
        paymentMethod?.allowPaymentBookingReference && paymentMethod?.requirePaymentBookingReference
          ? yup
              .string()
              .required(getRequiredMessage(intl, paymentMethod.labelForPaymentBookingReference))
          : yup.string().notRequired(),
      caseNumber:
        paymentMethod?.allowCaseNumber && paymentMethod?.requireCaseNumber
          ? yup.string().required(getRequiredMessage(intl, paymentMethod.labelForCaseNumber))
          : yup.string().notRequired(),
      languageId: yup.string().required(
        getRequiredMessage(
          intl,
          intl.formatMessage({
            id: translationKeys.create_interpretation_order_information_language_label,
          }),
        ),
      ),
      dialect: yup.string().notRequired(),
      dates: yup
        .array()
        .of(
          yup.object().shape({
            date: yup.date().required(
              intl.formatMessage({
                id: translationKeys.form_error_required_without_field_name,
              }),
            ),
            startTime: yup.object().required(
              intl.formatMessage({
                id: translationKeys.form_error_required_without_field_name,
              }),
            ),
            finishTime: yup
              .object()
              .required(
                intl.formatMessage({
                  id: translationKeys.form_error_required_without_field_name,
                }),
              )
              .test('time-difference', '', (_, ctx) => {
                const { startTime, finishTime } = ctx.parent;
                if (finishTime.id <= startTime.id) {
                  return finishTime.id + 24 * 60 - startTime.id >= 15;
                }
                return finishTime.id - startTime.id >= 15;
              }),
          }),
        )
        .min(
          1,
          getRequiredMessage(
            intl,
            intl.formatMessage({
              id: translationKeys.create_interpretation_order_information_date_label,
            }),
          ),
        ),
      isBookingThresholdConfirmationRequired: yup.boolean().required(),
      bookingThresholdConfirmation: yup
        .boolean()
        .when(
          ['isBookingThresholdConfirmationRequired'],
          ([isBookingThresholdConfirmationRequired]: boolean[], sch) =>
            isBookingThresholdConfirmationRequired ? sch.isTrue() : sch,
        ),
      jobCategory: isCategoryRequired
        ? yup.object().required(
            getRequiredMessage(
              intl,
              intl.formatMessage({
                id: translationKeys.create_interpretation_order_information_category_label,
              }),
            ),
          )
        : yup.object().notRequired(),
      subject: isCategorySubjectRequired
        ? yup.string().required(
            getRequiredMessage(
              intl,
              intl.formatMessage({
                id: translationKeys.create_interpretation_order_information_subject_label,
              }),
            ),
          )
        : yup.string().notRequired(),
      sessionType: yup.string().required(),
      assignmentVideoSolution: yup
        .string()
        .when(
          ['sessionType', 'assignmentInPersonAlternative'],
          ([sessionType, assignmentInPersonAlternative], sch) => {
            if (
              sessionType === ManagerJobSessionType.Video ||
              (sessionType === ManagerJobSessionType.InPerson &&
                assignmentInPersonAlternative === AssignmentInPersonAlternative.VideoInterpreter)
            ) {
              return sch
                .oneOf([AssignmentVideoSolution.SalitaVideo, AssignmentVideoSolution.OwnVideo])
                .required(
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_assignment_type_video_solution_label,
                    }),
                  ),
                );
            } else {
              return sch.notRequired();
            }
          },
        ),
      videoPlatform: yup
        .object()
        .when(
          ['sessionType', 'assignmentVideoSolution', 'assignmentInPersonAlternative'],
          (
            [sessionType, assignmentVideoSolution, assignmentInPersonAlternative]:
              | string[]
              | null[],
            sch,
          ) =>
            (assignmentVideoSolution === AssignmentVideoSolution.OwnVideo &&
              sessionType === ManagerJobSessionType.Video) ||
            (sessionType === ManagerJobSessionType.InPerson &&
              assignmentInPersonAlternative === AssignmentInPersonAlternative.VideoInterpreter)
              ? sch.required(
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_assignment_type_video_system_label,
                    }),
                  ),
                )
              : sch.notRequired(),
        ),
      videoPlatformDataRequired: yup.boolean(),
      videoPlatformUrl: yup
        .string()
        .when(
          [
            'sessionType',
            'assignmentVideoSolution',
            'videoPlatformDataRequired',
            'assignmentInPersonAlternative',
          ],
          (
            [
              sessionType,
              assignmentVideoSolution,
              videoPlatformDataRequired,
              assignmentInPersonAlternative,
            ]: string[] | null[],
            sch,
          ) => {
            return videoPlatformDataRequired
              ? (assignmentVideoSolution === AssignmentVideoSolution.OwnVideo &&
                  sessionType === ManagerJobSessionType.Video) ||
                (sessionType === ManagerJobSessionType.InPerson &&
                  assignmentVideoSolution === AssignmentVideoSolution.OwnVideo &&
                  assignmentInPersonAlternative === AssignmentInPersonAlternative.VideoInterpreter)
                ? sch.required(
                    getRequiredMessage(
                      intl,
                      intl.formatMessage({
                        id: translationKeys.create_interpretation_order_assignment_type_video_system_url_label,
                      }),
                    ),
                  )
                : sch.notRequired()
              : sch.notRequired();
          },
        ),
      videoPlatformInstructions: yup
        .string()
        .when(
          [
            'sessionType',
            'assignmentVideoSolution',
            'videoPlatformDataRequired',
            'assignmentInPersonAlternative',
          ],
          (
            [
              sessionType,
              assignmentVideoSolution,
              videoPlatformDataRequired,
              assignmentInPersonAlternative,
            ]: string[] | null[],
            sch,
          ) =>
            videoPlatformDataRequired
              ? (assignmentVideoSolution === AssignmentVideoSolution.OwnVideo &&
                  sessionType === ManagerJobSessionType.Video) ||
                (sessionType === ManagerJobSessionType.InPerson &&
                  assignmentVideoSolution === AssignmentVideoSolution.OwnVideo &&
                  assignmentInPersonAlternative === AssignmentInPersonAlternative.VideoInterpreter)
                ? sch.required(
                    getRequiredMessage(
                      intl,
                      intl.formatMessage({
                        id: translationKeys.create_interpretation_order_assignment_type_video_instructions_label,
                      }),
                    ),
                  )
                : sch.notRequired()
              : sch.notRequired(),
        ),
      actualCreatedAtDate: yup.date().required(),
      actualCreatedAtTime: yup.object<TimePickerItem>().required(),
      assignmentVideoAlternative: yup.string().when(['sessionType'], ([sessionType], sch) => {
        if (sessionType === ManagerJobSessionType.Video) {
          return sch.required(
            getRequiredMessage(
              intl,
              intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_alternative_type_label,
              }),
            ),
          );
        } else {
          return sch.notRequired();
        }
      }),
      assignmentInPersonAlternative: yup.string().when(['sessionType'], ([sessionType], sch) => {
        if (sessionType === ManagerJobSessionType.InPerson) {
          return sch.required(
            getRequiredMessage(
              intl,
              intl.formatMessage({
                id: translationKeys.create_interpretation_order_assignment_type_alternative_type_label,
              }),
            ),
          );
        } else {
          return sch.notRequired();
        }
      }),
      contactPersonName: yup
        .string()
        .when(['sessionType'], ([sessionType]: string[] | null[], sch) =>
          sessionType === ManagerJobSessionType.InPerson
            ? sch.required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_person_label,
                  }),
                ),
              )
            : sch.notRequired(),
        ),
      contactPersonCountryCode: yup
        .object()
        .when(['sessionType'], ([sessionType]: string[] | null[], sch) =>
          sessionType === ManagerJobSessionType.InPerson
            ? sch.required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_country_code_label,
                  }),
                ),
              )
            : sch.notRequired(),
        ),
      contactPersonDirections: yup.string(),
      contactPersonPhoneNumber: yup
        .string()
        .when(['sessionType'], ([sessionType]: string[] | null[], sch) =>
          sessionType === ManagerJobSessionType.InPerson
            ? sch.required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_assignment_type_in_person_contact_phone_number_label,
                  }),
                ),
              )
            : sch.notRequired(),
        ),
      contactPersonAddress: yup
        .object()
        .when(
          ['sessionType', 'contactPersonAddressType'],
          ([sessionType, contactPersonAddressType]: string[] | null[], sch) => {
            if (sessionType !== ManagerJobSessionType.InPerson) return sch.notRequired();

            const requiredStringSchema = (id: string) =>
              yup.string().required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id,
                  }),
                ),
              );

            if (contactPersonAddressType === AddressType.Google) {
              return sch.shape({
                label: requiredStringSchema(
                  translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_label,
                ),
                customData: yup.object({
                  line1: requiredStringSchema(
                    translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_line_1_label,
                  ),
                  line2: yup.string().notRequired(),
                  postcode: requiredStringSchema(
                    translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_postcode_label,
                  ),
                  city: requiredStringSchema(
                    translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_city_label,
                  ),
                  county: requiredStringSchema(
                    translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_county_label,
                  ),
                  country: requiredStringSchema(
                    translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_country_label,
                  ),
                }),
              });
            }

            // Fallback if the address type is not Google and it didn't contain all customData.
            return sch.shape({
              label: requiredStringSchema(
                translationKeys.create_interpretation_order_assignment_type_in_person_contact_address_label,
              ),
              customData: yup.object({}),
            });
          },
        ),
      contactPersonAddressType: yup.string<AddressType>().required(),
      contactPersonAddressDirections: yup.string().notRequired(),

      alternativeLanguageActive: yup.boolean().default(false),
      alternativeLanguageTo: yup
        .object()
        .nullable()
        .when(['alternativeLanguageActive'], ([active], sch) =>
          active
            ? sch.nonNullable().required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_alternative_language_label,
                  }),
                ),
              )
            : sch.notRequired(),
        ),
      specificInterpreterActive: yup.boolean().default(false),
      specificInterpreter: yup
        .object()
        .nullable()
        .when(['specificInterpreterActive'], ([active], sch) =>
          active
            ? sch.nonNullable().required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_specific_interpreter_label,
                  }),
                ),
              )
            : sch.notRequired(),
        ),
      specificInterpreterProcessing: yup
        .string()
        .nullable()
        .when(['specificInterpreterActive', 'specificInterpreter'], ([active, interpreter], sch) =>
          active && interpreter
            ? sch.nonNullable().required(getRequiredMessage(intl, 'This'))
            : sch.notRequired(),
        ),
      specificGenderActive: yup.boolean().default(false),
      specificGender: yup
        .string()
        .nullable()
        .when(['specificGenderActive'], ([active], sch) =>
          active
            ? sch.nonNullable().required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_specific_gender_title,
                  }),
                ),
              )
            : sch.notRequired(),
        ),
      genderProcessing: yup
        .string()
        .nullable()
        .when(['specificGenderActive', 'specificGender'], ([active, gender], sch) =>
          active && gender
            ? sch.nonNullable().required(getRequiredMessage(intl, 'This'))
            : sch.notRequired(),
        ),
      manualBookingAutoAward: yup.boolean().default(false),
      manualBookingAutoInvite: yup.boolean().default(false),
      manualBookingFeeApplied: yup.boolean().default(false),
      manualBookingStandBy: yup.boolean().default(false),
      manualEditingFeeApplied: yup.boolean().default(false),
      manualTravelBookingFeeApplied: yup.boolean().default(false),
      matchInterpreterWith: yup.string<InterpreterMatchOptions>(),
      qualificationLevelActive: yup.boolean().default(false),
      specificQualification: yup
        .object()
        .nullable()
        .when(['qualificationLevelActive'], ([active], sch) =>
          active
            ? sch.nonNullable().required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_qualification_level_label,
                  }),
                ),
              )
            : sch.notRequired(),
        ),
      qualificationProcessing: yup
        .string()
        .nullable()
        .when(
          ['qualificationLevelActive', 'specificQualification'],
          ([active, specificQualification], sch) =>
            active && specificQualification
              ? sch.nonNullable().required(getRequiredMessage(intl, 'This'))
              : sch.notRequired(),
        ),
      bookingMechanism: yup.string<ManagerJobBookingMechanism>().required(),
      blockInterpretersActive: yup.boolean().default(false),
      blockedInterpreters: yup
        .array()
        .nullable()
        .when(['blockInterpretersActive'], ([active], sch) =>
          active
            ? sch
                .nonNullable()
                .min(
                  1,
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_settings_block_interpreters_label,
                    }),
                  ),
                )
                .required(
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_settings_block_interpreters_label,
                    }),
                  ),
                )
            : sch.notRequired(),
        ),
      blockCitiesActive: yup.boolean().default(false),
      blockedCities: yup
        .array()
        .nullable()
        .when(['blockCitiesActive'], ([active], sch) =>
          active
            ? sch
                .nonNullable()
                .min(
                  1,
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_settings_block_cities_label,
                    }),
                  ),
                )
                .required(
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_settings_block_cities_label,
                    }),
                  ),
                )
            : sch.notRequired(),
        ),
      messageToInterpreterActive: yup.boolean().default(false),
      messageToInterpreter: yup
        .string()
        .nullable()
        .when(['messageToInterpreterActive'], ([active], sch) =>
          active
            ? sch.nonNullable().required(
                getRequiredMessage(
                  intl,
                  intl.formatMessage({
                    id: translationKeys.create_interpretation_order_settings_message_to_interpreter_label,
                  }),
                ),
              )
            : sch.notRequired(),
        ),
      uploadFilesActive: yup.boolean().default(false),
      attachments: yup
        .array()
        .nullable()
        .when(['uploadFilesActive'], ([active], sch) =>
          active
            ? sch
                .nonNullable()
                .min(
                  1,
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_settings_upload_file_title,
                    }),
                  ),
                )
                .required(
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_settings_upload_file_title,
                    }),
                  ),
                )
            : sch.notRequired(),
        ),
      deadlineConfirmationActive: yup.boolean().default(false),
      confirmationByDate: yup
        .date()
        .when(
          ['deadlineConfirmationActive'],
          ([deadlineConfirmationActive]: boolean[] | null[], sch) =>
            deadlineConfirmationActive
              ? sch.required(
                  getRequiredMessage(
                    intl,
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_manual_booking_date_label,
                    }),
                  ),
                )
              : sch.notRequired(),
        ),
      confirmationByTime: yup
        .object()
        .when(
          ['deadlineConfirmationActive'],
          ([deadlineConfirmationActive]: boolean[] | null[], sch) =>
            deadlineConfirmationActive
              ? sch
                  .required(
                    getRequiredMessage(
                      intl,
                      intl.formatMessage({
                        id: translationKeys.create_interpretation_order_manual_booking_time_label,
                      }),
                    ),
                  )
                  .test(
                    'time-difference',
                    intl.formatMessage({
                      id: translationKeys.create_interpretation_order_manual_booking_set_deadline_cant_be_later_error,
                    }),
                    (value, ctx) => {
                      const deadlineDateValue = addTimeToDate(
                        ctx.parent.confirmationByDate,
                        value as TimePickerItem, // TODO refactor with the schema improvement task
                      ).valueOf();
                      const startDateValue = addTimeToDate(
                        ctx.parent.dates[0].date,
                        ctx.parent.dates[0].startTime,
                      ).valueOf();

                      return deadlineDateValue < startDateValue;
                    },
                  )
              : sch.notRequired(),
        ),
      saveAddress: yup.boolean().default(false).notRequired(),
    })
    .concat(customerSchemaInstance);
};
