import { InterpretationSkillSex } from './InterpretationSkill';
import { InterpreterAvailability } from './InterpreterAvailability';
import { ManagerJobAddress } from './ManagerJob';

export enum ManagerJobSuitableInterpreterStatus {
  Available = 'available',
  NotAvailable = 'not_available',
  NotSuitable = 'not_suitable',
  Blocked = 'blocked',
}

export enum ManagerJobSuitableInterpreterDiscussionStatus {
  Invited = 'invited',
  Applied = 'applied',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Declined = 'declined',
  Withdrawn = 'withdrawn',
  Abandoned = 'abandoned',
  Hidden = 'hidden',
  Cancelled = 'cancelled',
}

interface ManagerJobSuitableInterpreterSkill {
  id: number;
  sourceLanguage: {
    id: number;
    code: string;
    name: string;
  };
  targetLanguage: {
    id: number;
    code: string;
    name: string;
  };
  qualification: {
    id: number;
    priority: number;
    name: string;
    shortName: string;
    tolkeregisterName: string | null;
  };
}

export interface ManagerJobSuitableInterpreter {
  person: {
    id: number;
    uid: string;
    name: string;
    avatar: string;
    email: string;
    phone: string;
    phoneCode: string;
    phoneNumber: string;
    gender: InterpretationSkillSex;
    emailVerified: boolean;
    isShared: boolean;
  };
  personAddress: ManagerJobAddress;
  availability: InterpreterAvailability;
  mainSkill: ManagerJobSuitableInterpreterSkill | null;
  alternativeSkill: ManagerJobSuitableInterpreterSkill | null;
  discussion: {
    id: number;
    invitationsCount: number;
    status: ManagerJobSuitableInterpreterDiscussionStatus;
    lastInvitedAt: string;
    lastInvitedVia: string;
  } | null;
  suitabilityStatus: ManagerJobSuitableInterpreterStatus;
  suitability: {
    allowed_for_auto_invite: boolean;
    allowed_for_manual_invite: boolean;
    has_no_overlapping_accepted_jobs: boolean;
    has_no_overlapping_blocking_calendar_events: boolean;
    has_no_overlapping_offtimes: boolean;
    has_no_skipped_discussions: boolean;
    has_no_unsuitable_payout_methods: boolean;
    is_available_for_standby: boolean;
    is_not_blocked_by_city: boolean;
    is_not_blocked_by_job: boolean;
    is_not_blocked_by_related_people: boolean;
    is_not_blocked_by_same_int_series_jobs: boolean;
    is_proactive_team_member: boolean;
    is_within_distance: boolean;
    job_in_time_range: boolean;
    job_is_available_to_interpreter: boolean;
    job_is_exclusive: boolean;
    job_is_not_finished_yet: boolean;
    job_is_visible: boolean;
    job_with_state: boolean;
    matching_direct_invitation: boolean;
    matching_gender: boolean;
    matching_police_job_compliance: boolean;
    matching_session_type: boolean;
    matching_skill: boolean;
    no_discussion_with_state: boolean;
  };
  hourlyRate: number;
  isFavorite: boolean;
  personNotesCount: number;
}

export interface ManagerJobSuitableInterpreterResponse {
  collection: ManagerJobSuitableInterpreter[];
  pagination: {
    count: number;
    page: number;
    pages: number;
  };
}
