import React from 'react';
import { IntlShape } from 'react-intl';
import { Tag } from '@skiwo/components';
import { addHours, format, startOfDay } from 'date-fns';
import translationKeys from '../../../../translations/translationKeys';
import { InterpretationSkillSex } from '../../../../types';
import { BaseContactAddress } from '../../../../types/EnterpriseSearchResult';
import {
  ManagerJobSuitableInterpreterDiscussionStatus,
  ManagerJobSuitableInterpreterStatus,
} from '../../../../types/ManagerJobSuitableInterpreter';

export function getHoursArray(hours = 24): string[] {
  const start = startOfDay(new Date());
  const end = addHours(start, hours);
  const hoursArray = [];

  for (let time = start; time < end; time = addHours(time, 1)) {
    hoursArray.push(format(time, 'HH:mm'));
  }

  return hoursArray;
}

export enum PersonStatus {
  Applied = 'applied',
  Available = 'available',
  NotAvailable = 'not-available',
  NotSuitable = 'not-suitable',
  Blocked = 'blocked',
}

export enum PersonReasons {
  Qualification = 'qualification',
  OtherJobs = 'other_jobs',
  SessionType = 'session_type',
  Availability = 'availability',
  PayoutMethod = 'payout_method',
  BlockedForAssignment = 'blocked_for_assignment',
  SessionTypeStatus = 'session_type_status',
  AccountStatus = 'account_status',
  BlockedCity = 'blocked_city',
  DeclinedInvitation = 'declined_invitation',
}

export enum SuitabilityReason {
  AllowedForAutoInvite = 'allowed_for_auto_invite',
  AllowedForManualInvite = 'allowed_for_manual_invite',
  HasNoOverlappingAcceptedJobs = 'has_no_overlapping_accepted_jobs',
  HasNoOverlappingBlockingCalendarEvents = 'has_no_overlapping_blocking_calendar_events',
  HasNoOverlappingOfftimes = 'has_no_overlapping_offtimes',
  HasNoSkippedDiscussions = 'has_no_skipped_discussions',
  HasNoUnsuitablePayoutMethods = 'has_no_unsuitable_payout_methods',
  IsAvailableForStandby = 'is_available_for_standby',
  IsNotBlockedByCity = 'is_not_blocked_by_city',
  IsNotBlockedByJob = 'is_not_blocked_by_job',
  IsNotBlockedByRelatedPeople = 'is_not_blocked_by_related_people',
  IsNotBlockedBySameIntSeriesJobs = 'is_not_blocked_by_same_int_series_jobs',
  IsProactiveTeamMember = 'is_proactive_team_member',
  IsWithinDistance = 'is_within_distance',
  JobInTimeRange = 'job_in_time_range',
  JobIsAvailableToInterpreter = 'job_is_available_to_interpreter',
  JobIsExclusive = 'job_is_exclusive',
  JobIsNotFinishedYet = 'job_is_not_finished_yet',
  JobIsVisible = 'job_is_visible',
  JobWithState = 'job_with_state',
  MatchingDirectInvitation = 'matching_direct_invitation',
  MatchingGender = 'matching_gender',
  MatchingPoliceJobCompliance = 'matching_police_job_compliance',
  MatchingSessionType = 'matching_session_type',
  MatchingSkill = 'matching_skill',
  NoDiscussionWithState = 'no_discussion_with_state',
}

export interface Person {
  uid: string;
  avatarUrl?: string;
  name: string;
  phone?: string;
  email?: string;
  status?: PersonStatus;
  location?: Partial<BaseContactAddress>;
  distance: number;
  gender?: InterpretationSkillSex;
  targetLanguage?: { name: string; qualificationLevel: string };
  alternativeLanguage?: { name: string; qualificationLevel: string };
  hourlyRate?: number;
  reason?: PersonReasons;
  invites?: number;
  isFavourite?: boolean;
  lastInvited?: {
    date: Date;
    via: string;
  };
  notes?: number;
}

export function renderStatusTag(
  status: ManagerJobSuitableInterpreterStatus | undefined,
  discussionStatus: ManagerJobSuitableInterpreterDiscussionStatus | undefined,
  intl: IntlShape,
): JSX.Element | null {
  switch (discussionStatus) {
    case ManagerJobSuitableInterpreterDiscussionStatus.Applied:
      return (
        <Tag color="success">
          {intl.formatMessage({
            id: translationKeys.job_interpreters_timeline_person_status_applied_label,
          })}
        </Tag>
      );
    case ManagerJobSuitableInterpreterDiscussionStatus.Declined:
      return (
        <Tag color="error">
          {intl.formatMessage({
            id: translationKeys.job_interpreters_timeline_person_status_declined_label,
          })}
        </Tag>
      );
    case ManagerJobSuitableInterpreterDiscussionStatus.Withdrawn:
      return (
        <Tag color="error">
          {intl.formatMessage({
            id: translationKeys.job_interpreters_timeline_person_status_withdrawn_label,
          })}
        </Tag>
      );
    default:
      switch (status) {
        case ManagerJobSuitableInterpreterStatus.Available:
          return (
            <Tag color="info">
              {intl.formatMessage({
                id: translationKeys.job_interpreters_timeline_person_status_available_label,
              })}
            </Tag>
          );
        case ManagerJobSuitableInterpreterStatus.Blocked:
          return (
            <Tag color="error">
              {intl.formatMessage({
                id: translationKeys.job_interpreters_timeline_person_status_blocked_label,
              })}
            </Tag>
          );
        case ManagerJobSuitableInterpreterStatus.NotAvailable:
          return (
            <Tag color="warning">
              {intl.formatMessage({
                id: translationKeys.job_interpreters_timeline_person_status_not_available_label,
              })}
            </Tag>
          );
        case ManagerJobSuitableInterpreterStatus.NotSuitable:
          return (
            <Tag color="neutral">
              {intl.formatMessage({
                id: translationKeys.job_interpreters_timeline_person_status_not_suitable_label,
              })}
            </Tag>
          );
        default:
          return null;
      }
  }
}

export function getReasonText(reason: SuitabilityReason, intl: IntlShape): string | undefined {
  switch (reason) {
    case SuitabilityReason.HasNoOverlappingBlockingCalendarEvents:
    case SuitabilityReason.HasNoOverlappingOfftimes:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_availability,
      });
    case SuitabilityReason.IsNotBlockedByCity:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_blocked_city,
      });
    case SuitabilityReason.IsNotBlockedByJob:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_blocked_for_assignment,
      });
    case SuitabilityReason.HasNoOverlappingAcceptedJobs:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_other_jobs,
      });
    case SuitabilityReason.HasNoUnsuitablePayoutMethods:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_payout_method,
      });
    case SuitabilityReason.MatchingSkill:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_qualification,
      });
    case SuitabilityReason.MatchingSessionType:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_session_type,
      });
    case SuitabilityReason.AllowedForManualInvite:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_allowed_for_manual_invite,
      });
    case SuitabilityReason.MatchingGender:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_reason_gender,
      });
    default:
      return undefined;
  }
}

export function getWarningBannerText(
  status: ManagerJobSuitableInterpreterStatus | undefined,
  intl: IntlShape,
): string | undefined {
  switch (status) {
    case ManagerJobSuitableInterpreterStatus.NotAvailable:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_banner_not_available,
      });
    case ManagerJobSuitableInterpreterStatus.NotSuitable:
      return intl.formatMessage({
        id: translationKeys.job_interpreters_timeline_banner_not_suitable,
      });
    default:
      return undefined;
  }
}
