import { IntlShape } from 'react-intl';
import translationKeys from '../../translations/translationKeys';
import { ManagerCustomerAccountStatus } from '../../types';

const getAccountStatusName = (type: ManagerCustomerAccountStatus | null, intl: IntlShape): string => {
  if (type === ManagerCustomerAccountStatus.Active) {
    return intl.formatMessage({ id: translationKeys.customers_page_status_active });
  }

  if (type === ManagerCustomerAccountStatus.Banned) {
    return intl.formatMessage({ id: translationKeys.customers_page_status_banned });
  }

  if (type === ManagerCustomerAccountStatus.Blocked) {
    return intl.formatMessage({ id: translationKeys.customers_page_status_blocked });
  }

  if (type === ManagerCustomerAccountStatus.Deceased) {
    return intl.formatMessage({ id: translationKeys.customers_page_status_deceased });
  }

  if (type === ManagerCustomerAccountStatus.Deleted) {
    return intl.formatMessage({ id: translationKeys.customers_page_status_deleted });
  }

  if (type === ManagerCustomerAccountStatus.Inactive) {
    return intl.formatMessage({ id: translationKeys.customers_page_status_inactive });
  }

  if (type === ManagerCustomerAccountStatus.Paused) {
    return intl.formatMessage({ id: translationKeys.customers_page_status_paused });
  }

  if (type === ManagerCustomerAccountStatus.Retired) {
    return intl.formatMessage({ id: translationKeys.customers_page_status_retired });
  }

  return '-';
};

export default getAccountStatusName;
