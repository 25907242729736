import React from 'react';
import { Placeholder } from 'react-bootstrap';
import styles from './PersonCellSkeleton.module.scss';

const PersonCellSkeleton = () => {
  return (
    <Placeholder as="span" animation="wave" className={styles.skeleton} data-testid="box-skeleton">
      <div className={styles.circles}>
        <Placeholder xs={2}  data-testid="circle" />
      </div>
      <div className={styles.details}>
        <Placeholder xs={5} className={styles.box} />
        <Placeholder xs={3} className={styles.box} />
        <div className={styles.flexRow}>
          <Placeholder xs={3} className={styles.tag} />
          <Placeholder xs={3} className={styles.tag} />
        </div>
      </div>
    </Placeholder>
  );
};

export default PersonCellSkeleton;
