import { IntlShape } from 'react-intl';
import translationKeys from '../translations/translationKeys';
import { InterpretationSkillSex } from '../types';

const getGenderName = (gender: InterpretationSkillSex, intl: IntlShape): string => {
  if (gender === InterpretationSkillSex.Male) {
    return intl.formatMessage({
      id: translationKeys.interpretation_skills_page_gender_male,
    });
  }

  if (gender === InterpretationSkillSex.Female) {
    return intl.formatMessage({
      id: translationKeys.interpretation_skills_page_gender_female,
    });
  }

  return '-';
};

export default getGenderName;
