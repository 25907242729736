import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Tag, Tooltip } from '@skiwo/components';
import { TagColor } from '@skiwo/components/src/Tag/Tag';
import translationKeys from '../../translations/translationKeys';
import styles from './QualificationLabel.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-types -- we need this to allow any string with an additional predefined set of values
type Category = 'A' | 'B' | 'C' | 'D' | 'E' | 'N/A' | (string & {});

const getColor = (category: Category): TagColor => {
  switch (category) {
    case 'A':
    case 'B':
      return 'success';
    case 'C':
      return 'info';
    case 'D':
    case 'E':
      return 'warning';
    case 'N/A':
    default:
      return 'neutral';
  }
};

const getCategoryHoverText = (category: Category, intl: IntlShape): string | undefined => {
  switch (category) {
    case 'A':
      return intl.formatMessage({
        id: translationKeys.qualification_label_category_a_tooltip,
      });
    case 'B':
      return intl.formatMessage({
        id: translationKeys.qualification_label_category_b_tooltip,
      });
    case 'C':
      return intl.formatMessage({
        id: translationKeys.qualification_label_category_c_tooltip,
      });
    case 'D':
      return intl.formatMessage({
        id: translationKeys.qualification_label_category_d_tooltip,
      });
    case 'E':
      return intl.formatMessage({
        id: translationKeys.qualification_label_category_e_tooltip,
      });
    default:
    case 'N/A':
      return intl.formatMessage({
        id: translationKeys.qualification_label_category_na_tooltip,
      });
  }
};

export default function QualificationLabel({
  category,
  language,
}: {
  category: Category;
  language: string;
}) {
  const intl = useIntl();
  return (
    <Tooltip title={getCategoryHoverText(category, intl)}>
      <div className={styles.qualificationLabel}>
        <Tag size="small" variant="square" color={getColor(category)}>
          {category}
        </Tag>
        {language}
      </div>
    </Tooltip>
  );
}
