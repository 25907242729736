import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Modal } from '@skiwo/components';
import { ApiError } from '../../../../Api';
import {
  useBlockInterpreterMutation,
  useUnblockInterpreterMutation,
} from '../../../../Api/Endpoints/Interpreters/Interpreters.hooks';
import { useToast } from '../../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../../translations/translationKeys';
import { ManagerJobSuitableInterpreter } from '../../../../types/ManagerJobSuitableInterpreter';
import PersonDetails from '../PersonDetails';
import styles from './BlockedInterpreterStatusModal.module.scss';

const getTranslationBasedOnState = (isUnblock: boolean, intl: IntlShape) => {
  const keyPrefix = isUnblock ? 'job_unblock_interpreter_modal' : 'job_block_interpreter_modal';

  return {
    title: intl.formatMessage({ id: translationKeys[`${keyPrefix}_title`] }),
    description: intl.formatMessage({ id: translationKeys[`${keyPrefix}_description`] }),
    submitButtonText: intl.formatMessage({ id: translationKeys[`${keyPrefix}_submit_label`] }),
    successMessage: intl.formatMessage({ id: translationKeys[`${keyPrefix}_success_message`] }),
  };
};

export default function BlockedInterpreterStatusModal({
  interpreter,
  show,
  onClose,
  isUnblock,
}: {
  isUnblock?: boolean;
  interpreter: ManagerJobSuitableInterpreter;
  show: boolean;
  onClose: () => void;
}) {
  const intl = useIntl();
  const { showErrorToast, showToast } = useToast();
  const blockMutation = useBlockInterpreterMutation();
  const unblockMutation = useUnblockInterpreterMutation();
  const translation = getTranslationBasedOnState(!!isUnblock, intl);

  return (
    <Modal
      show={show}
      onHide={onClose}
      {...translation}
      submitButtonVariant="danger"
      isSubmitDisabled={blockMutation.isPending || unblockMutation.isPending}
      size="medium"
      onCancel={onClose}
      onSubmit={async () => {
        try {
          isUnblock
            ? unblockMutation.mutateAsync(interpreter.person.uid)
            : blockMutation.mutateAsync(interpreter.person.uid);
          showToast({
            message: translation.successMessage,
            variant: 'success',
          });
        } catch (error) {
          showErrorToast(error as ApiError);
        } finally {
          onClose();
        }
      }}
    >
      <PersonDetails className={styles.cardWrapper} interpreter={interpreter} isAvatarActive />
    </Modal>
  );
}
