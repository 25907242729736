import React, { useState } from 'react';
import { Form as B_Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Drawer, SearchDropdown, TextField } from '@skiwo/components';
import { Form, Formik } from 'formik';
import {
  useGetDemanderPricingTemplatesQuery,
  useGetSupplierPricingTemplatesQuery,
} from '../../../Api/Endpoints/Finance/Finance.hooks';
import { useUpdateJobMutation } from '../../../Api/Endpoints/Jobs/Jobs.hooks';
import ExpandableToggle from '../../../components/ExpandableToggle/ExpandableToggle';
import { PaymentMethodSelector } from '../../../components/PaymentMethodSelector/PaymentMethodSelector';
import PaymentRegexHint from '../../../components/PaymentRegexHint/PaymentRegexHint';
import usePaymentRegexHint from '../../../components/PaymentRegexHint/usePaymentRegexHint';
import { ManualBookingActualCreatedAt } from '../../../CreateInterpretationOrder/ManualBookingSection/ManualBookingActualCreatedAt';
import { ManualBookingConfirmations } from '../../../CreateInterpretationOrder/ManualBookingSection/ManualBookingConfirmations';
import { ManualBookingFees } from '../../../CreateInterpretationOrder/ManualBookingSection/ManualBookingFees';
import { addTimeToDate, getTimeOption } from '../../../CreateInterpretationOrder/utils';
import { appendNestedFormObjectWithEmptyValues } from '../../../helpers/appendNestedFormDataObject';
import { getDefaultReferences } from '../../../helpers/getDefaultReferences';
import { getTypesafeSetFieldValue } from '../../../helpers/getTypesafeSetFieldValue';
import { useToast } from '../../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../../translations/translationKeys';
import { CustomerPaymentMethod } from '../../../types';
import { ManagerJobBookingMechanism, ManagerJobDetails } from '../../../types/ManagerJob';
import { ManagerJobFinanceStatus } from '../../../types/ManagerJob';
import { getPricingTemplateOptions } from '../../utils/getPricingTemplateOptions';
import { updateJobErrorKeys } from '../../utils/updateJobErrorKeys';
import {
  JobDetailsDrawerName,
  useJobDetailsDrawer,
} from '../JobDetailsDrawerContext/JobDetailsDrawerContext';
import { EditInvoicingFormValues, schema } from './schema';
import styles from './EditInvoicingDrawer.module.scss';

interface EditInvoicingDrawerProps {
  job: ManagerJobDetails;
}

const EditInvoicingDrawer = ({ job }: EditInvoicingDrawerProps) => {
  const intl = useIntl();
  const [showManualBooking, setShowManualBooking] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    CustomerPaymentMethod | undefined
  >();
  const { regexHintIcon, matchRegex } = usePaymentRegexHint({
    selectedPaymentMethod: selectedPaymentMethod,
    defaultBookingReference: job.info.bookingReference || undefined,
    defaultPaymentBookingReference: job.info.paymentBookingReference || undefined,
  });
  const currentDate = new Date();
  const actualCreatedAtDate = job?.actualCreatedAt ? new Date(job.actualCreatedAt) : currentDate;
  const updateJob = useUpdateJobMutation();
  const { showErrorToast, showToast } = useToast();
  const { data: demanderPricingTemplates } = useGetDemanderPricingTemplatesQuery();
  const { data: supplierPricingTemplates } = useGetSupplierPricingTemplatesQuery();
  const { closeDrawer, isDrawerOpen } = useJobDetailsDrawer();

  const isJobReadyForFinance = job.financeStatus === ManagerJobFinanceStatus.ReadyForFinance;
  const isDrawerOpened = isDrawerOpen(JobDetailsDrawerName.EditInvoicingDrawer);
  const handleCloseDrawer = () => closeDrawer(JobDetailsDrawerName.EditInvoicingDrawer);

  const initialValues: EditInvoicingFormValues = {
    paymentMethodId: job.paymentMethod.id.toString(),
    bookingReference: job.info.bookingReference || undefined,
    paymentBookingReference: job.info.paymentBookingReference || undefined,
    caseNumber: job.info.caseNumber || undefined,
    actualCreatedAtDate,
    actualCreatedAtTime: getTimeOption(actualCreatedAtDate),
    bookingMechanism: job?.bookingMechanism ?? ManagerJobBookingMechanism.Phone,
    manualBookingFeeApplied: job.info.manualBookingFeeApplied,
    manualEditingFeeApplied: job.info.manualEditingFeeApplied,
    manualTravelBookingFeeApplied: job.info.manualTravelBookingFeeApplied,
    deadlineConfirmationActive: !!job.interpretationRequirement.confirmationBy,
    confirmationByDate: job.interpretationRequirement.confirmationBy
      ? new Date(job.interpretationRequirement.confirmationBy)
      : new Date(),
    confirmationByTime: job.interpretationRequirement.confirmationBy
      ? getTimeOption(new Date(job.interpretationRequirement.confirmationBy))
      : undefined,
    pricingTemplateDemanderCustomerId: job.demanderPricingTemplates?.external?.id.toString(),
    pricingTemplateSupplierHonorarId: job.supplierPricingTemplates?.honorar?.id.toString(),
    pricingTemplateSupplierCompanyId: job.supplierPricingTemplates?.company?.id.toString(),
    startTime: job.interpretationRequirement.startTime,
  };

  const handleSubmit = (values: EditInvoicingFormValues) => {
    const getUpdatedPricingReferences = () => {
      if (job.isLocked) return {};

      const pricingReferences = {
        demanderExternalPricingTemplateId: values.pricingTemplateDemanderCustomerId,
        supplierHonorarPricingTemplateId: values.pricingTemplateSupplierHonorarId,
        supplierCompanyPricingTemplateId: values.pricingTemplateSupplierCompanyId,
      };
      if (
        job.demanderPricingTemplates?.external?.id.toString() ===
        values.pricingTemplateDemanderCustomerId
      ) {
        delete pricingReferences.demanderExternalPricingTemplateId;
      }
      if (
        job.supplierPricingTemplates?.honorar?.id.toString() ===
        values.pricingTemplateSupplierHonorarId
      ) {
        delete pricingReferences.supplierHonorarPricingTemplateId;
      }
      if (
        job.supplierPricingTemplates?.company?.id.toString() ===
        values.pricingTemplateSupplierCompanyId
      ) {
        delete pricingReferences.supplierCompanyPricingTemplateId;
      }
      return pricingReferences;
    };

    const payload = {
      paymentMethodId: selectedPaymentMethod?.id,
      info: {
        bookingReference: values.bookingReference,
        paymentBookingReference: values.paymentBookingReference,
        caseNumber: values.caseNumber,
        manualBookingFeeApplied: values.manualBookingFeeApplied,
        manualEditingFeeApplied: values.manualEditingFeeApplied,
        manualTravelBookingFeeApplied: values.manualTravelBookingFeeApplied,
      },
      interpretationRequirement: {
        // I need to pass these 4 values because otherwise BE throw errors that these values has been changed
        sessionType: job.interpretationRequirement.sessionType,
        languageToId: job.interpretationRequirement.languageToId,
        startTime: job.interpretationRequirement.startTime,
        finishTime: job.interpretationRequirement.finishTime,

        confirmationBy:
          values.deadlineConfirmationActive &&
          values.confirmationByDate &&
          values.confirmationByTime
            ? addTimeToDate(values.confirmationByDate, values.confirmationByTime).toString()
            : '',
      },
      actualCreatedAt: addTimeToDate(
        values.actualCreatedAtDate,
        values.actualCreatedAtTime,
      ).toString(),
      bookingMechanism: values.bookingMechanism,
      ...getUpdatedPricingReferences(),
    };
    const updateOrderFormData = new FormData();

    appendNestedFormObjectWithEmptyValues(updateOrderFormData, payload);

    updateJob.mutate(
      { id: job.id, body: updateOrderFormData },
      {
        onSuccess: () => {
          handleCloseDrawer();
          showToast({
            variant: 'success',
            message: intl.formatMessage({
              id: translationKeys.job_edit_information_updated_successfully,
            }),
          });
        },
        onError: (error) => {
          showErrorToast(error, updateJobErrorKeys);
        },
      },
    );
  };

  const { defaultReference: defaultBookingReference } = getDefaultReferences({
    allowReference: selectedPaymentMethod?.allowBookingReference,
    customerDefaultReference: job.owner?.defaultBookingReference,
    departmentDefaultReference: job.department?.defaultBookingReference,
    enterpriseDefaultReference: job.enterprise?.defaultBookingReference,
  });

  const { defaultReference: defaultPaymentBookingReference } = getDefaultReferences({
    allowReference: selectedPaymentMethod?.allowPaymentBookingReference,
    customerDefaultReference: job.owner?.defaultPaymentBookingReference,
    departmentDefaultReference: job.department?.defaultPaymentBookingReference,
    enterpriseDefaultReference: job.enterprise?.defaultPaymentBookingReference,
  });

  return (
    <Formik<EditInvoicingFormValues>
      validationSchema={schema({ paymentMethod: selectedPaymentMethod, intl })}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formikProps) => {
        const setFieldValue = getTypesafeSetFieldValue(formikProps);
        const getBookerRefDecoration = () => {
          if (!defaultBookingReference) return;

          if (formikProps.values.bookingReference === defaultBookingReference) {
            return (
              <span className={styles.defaultIndicator}>
                <FormattedMessage
                  id={translationKeys.translation_order_edit_invoicing_default_label}
                />
              </span>
            );
          }

          return (
            <FontAwesomeIcon
              icon={faRotateLeft}
              onClick={() => setFieldValue('bookingReference', defaultBookingReference)}
            />
          );
        };

        const getInvoiceRefDecoration = () => {
          if (!defaultPaymentBookingReference) return;

          if (formikProps.values.paymentBookingReference === defaultPaymentBookingReference) {
            return (
              <span className={styles.defaultIndicator}>
                <FormattedMessage
                  id={translationKeys.translation_order_edit_invoicing_default_label}
                />
              </span>
            );
          }

          return (
            <FontAwesomeIcon
              icon={faRotateLeft}
              onClick={() =>
                setFieldValue('paymentBookingReference', defaultPaymentBookingReference)
              }
            />
          );
        };

        return (
          <Drawer
            show={isDrawerOpened}
            title={intl.formatMessage({ id: translationKeys.job_edit_invoicing_label })}
            onClose={handleCloseDrawer}
          >
            <Form className={styles.form}>
              {job.owner?.person && job.enterprise && (
                <PaymentMethodSelector
                  customerUid={job.owner.person?.uid}
                  customerName={job.owner.person?.name}
                  customerEnterpriseId={job.enterprise.id.toString()}
                  customerEnterpriseName={job.enterprise.name}
                  initialPaymentMethodId={formikProps.values.paymentMethodId}
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  disabled={isJobReadyForFinance}
                />
              )}
              <hr />
              {selectedPaymentMethod && (
                <>
                  <div className={styles.inputRow}>
                    {selectedPaymentMethod.allowBookingReference && (
                      <div>
                        <TextField
                          label={selectedPaymentMethod.labelForBookingReference || intl.formatMessage({ id: translationKeys.booking_reference_default_label })}
                          placeholder={`${selectedPaymentMethod.labelForBookingReference || intl.formatMessage({ id: translationKeys.booking_reference_default_label })}...`}
                          size="large"
                          decorationView={getBookerRefDecoration()}
                          onBlur={formikProps.handleBlur}
                          onChange={(e) => {
                            formikProps.handleChange(e);
                            matchRegex(e);
                          }}
                          errorText={
                            formikProps.touched.bookingReference
                              ? formikProps.errors.bookingReference
                              : undefined
                          }
                          value={formikProps.values.bookingReference}
                          disabled={isJobReadyForFinance}
                          name="bookingReference"
                          data-testid="booking-reference"
                        />
                        <PaymentRegexHint
                          reference={formikProps.values.bookingReference}
                          referenceRegex={selectedPaymentMethod.bookingReferenceRegex}
                          referenceRegexHint={selectedPaymentMethod.bookingReferenceRegexHint}
                          icon={regexHintIcon.bookingRefIcon}
                        />
                      </div>
                    )}
                    {selectedPaymentMethod.allowPaymentBookingReference && (
                      <div>
                        <TextField
                          label={selectedPaymentMethod.labelForPaymentBookingReference || intl.formatMessage({ id: translationKeys.payment_booking_reference_default_label })}
                          placeholder={`${selectedPaymentMethod.labelForPaymentBookingReference || intl.formatMessage({ id: translationKeys.payment_booking_reference_default_label })}...`}
                          size="large"
                          decorationView={getInvoiceRefDecoration()}
                          onBlur={formikProps.handleBlur}
                          onChange={(e) => {
                            formikProps.handleChange(e);
                            matchRegex(e);
                          }}
                          errorText={
                            formikProps.touched.paymentBookingReference
                              ? formikProps.errors.paymentBookingReference
                              : undefined
                          }
                          value={formikProps.values.paymentBookingReference}
                          disabled={isJobReadyForFinance}
                          name="paymentBookingReference"
                          data-testid="payment-booking-reference"
                        />
                        <PaymentRegexHint
                          reference={formikProps.values.paymentBookingReference}
                          referenceRegex={selectedPaymentMethod.paymentBookingReferenceRegex}
                          referenceRegexHint={
                            selectedPaymentMethod.paymentBookingReferenceRegexHint
                          }
                          icon={regexHintIcon.paymentBookingRefIcon}
                        />
                      </div>
                    )}
                  </div>
                  {selectedPaymentMethod?.allowCaseNumber && (
                    <TextField
                      label={selectedPaymentMethod.labelForCaseNumber || intl.formatMessage({ id: translationKeys.case_number_default_label })}
                      placeholder={`${selectedPaymentMethod.labelForCaseNumber || intl.formatMessage({ id: translationKeys.case_number_default_label })}...`}
                      size="large"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      errorText={
                        formikProps.touched.caseNumber ? formikProps.errors.caseNumber : undefined
                      }
                      value={formikProps.values.caseNumber}
                      disabled={isJobReadyForFinance}
                      name="caseNumber"
                      data-testid="case-number"
                    />
                  )}
                </>
              )}
              <span className={styles.sectionTitle}>
                <FormattedMessage id={translationKeys.job_edit_invoicing_pricing_templates} />
              </span>
              {demanderPricingTemplates && (
                <SearchDropdown
                  size="large"
                  label={intl.formatMessage({
                    id: translationKeys.job_edit_invoicing_pricing_templates_customer,
                  })}
                  selectedKeys={
                    formikProps.values.pricingTemplateDemanderCustomerId
                      ? [formikProps.values.pricingTemplateDemanderCustomerId.toString()]
                      : []
                  }
                  options={getPricingTemplateOptions(demanderPricingTemplates.templates.external)}
                  onChange={(items) => {
                    if (items && items?.length > 0) {
                      setFieldValue('pricingTemplateDemanderCustomerId', items[0].id.toString());
                    }
                  }}
                  disabled={job.isLocked}
                />
              )}
              {supplierPricingTemplates && (
                <SearchDropdown
                  size="large"
                  label={intl.formatMessage({
                    id: translationKeys.job_edit_invoicing_pricing_templates_honorar,
                  })}
                  selectedKeys={
                    formikProps.values.pricingTemplateSupplierHonorarId
                      ? [formikProps.values.pricingTemplateSupplierHonorarId.toString()]
                      : []
                  }
                  options={getPricingTemplateOptions(supplierPricingTemplates?.templates.honorar)}
                  onChange={(items) => {
                    if (items && items?.length > 0) {
                      setFieldValue('pricingTemplateSupplierHonorarId', items[0].id.toString());
                    }
                  }}
                  disabled={job.isLocked}
                />
              )}
              {supplierPricingTemplates && (
                <SearchDropdown
                  size="large"
                  label={intl.formatMessage({
                    id: translationKeys.job_edit_invoicing_pricing_templates_company,
                  })}
                  selectedKeys={
                    formikProps.values.pricingTemplateSupplierCompanyId
                      ? [formikProps.values.pricingTemplateSupplierCompanyId.toString()]
                      : []
                  }
                  options={getPricingTemplateOptions(supplierPricingTemplates.templates.company)}
                  onChange={(items) => {
                    if (items && items?.length > 0) {
                      setFieldValue('pricingTemplateSupplierCompanyId', items[0].id.toString());
                    }
                  }}
                  disabled={job.isLocked}
                />
              )}
              <ExpandableToggle
                title={intl.formatMessage({
                  id: translationKeys.job_edit_invoicing_manual_booking,
                })}
                description={intl.formatMessage({
                  id: translationKeys.job_edit_invoicing_manual_booking_description,
                })}
                active={showManualBooking}
                disabled={isJobReadyForFinance}
                action={
                  <B_Form.Check
                    data-testid="alternative-language-switch"
                    type="switch"
                    checked={showManualBooking}
                    disabled={isJobReadyForFinance}
                    onChange={() =>
                      setShowManualBooking((prevShowManualBooking) => !prevShowManualBooking)
                    }
                  />
                }
              >
                <ManualBookingActualCreatedAt
                  actualCreatedAtDate={formikProps.values.actualCreatedAtDate}
                  actualCreatedAtTime={formikProps.values.actualCreatedAtTime}
                  bookingMechanism={formikProps.values.bookingMechanism}
                  setActualCreatedAtDate={(date) => setFieldValue('actualCreatedAtDate', date)}
                  setActualCreatedAtTime={(time) => setFieldValue('actualCreatedAtTime', time)}
                  setBookingMechanism={(value) => setFieldValue('bookingMechanism', value)}
                />
                <hr />
                <ManualBookingFees
                  manualBookingFeeApplied={formikProps.values.manualBookingFeeApplied}
                  manualEditingFeeApplied={formikProps.values.manualEditingFeeApplied}
                  manualTravelBookingFeeApplied={formikProps.values.manualTravelBookingFeeApplied}
                  setManualBookingFeeApplied={(value) =>
                    setFieldValue('manualBookingFeeApplied', value)
                  }
                  setManualEditingFeeApplied={(value) =>
                    setFieldValue('manualEditingFeeApplied', value)
                  }
                  setManualTravelBookingFeeApplied={(value) =>
                    setFieldValue('manualTravelBookingFeeApplied', value)
                  }
                />
                <hr />
                <ManualBookingConfirmations
                  deadlineConfirmationActive={formikProps.values.deadlineConfirmationActive}
                  confirmationByDate={formikProps.values.confirmationByDate}
                  confirmationByTime={formikProps.values.confirmationByTime}
                  setDeadlineConfirmationActive={(value) =>
                    setFieldValue('deadlineConfirmationActive', value)
                  }
                  setConfirmationByDate={(date) => setFieldValue('confirmationByDate', date)}
                  setConfirmationByTime={(time) => setFieldValue('confirmationByTime', time)}
                  hasConfirmationError={
                    (formikProps.touched.confirmationByDate ||
                      formikProps.touched.confirmationByTime) &&
                    !!(
                      formikProps.errors.confirmationByDate || formikProps.errors.confirmationByTime
                    )
                  }
                  confirmationError={
                    formikProps.errors.confirmationByDate || formikProps.errors.confirmationByTime
                  }
                />
              </ExpandableToggle>
              <footer className={styles.formFooter}>
                <Button size="x-large" variant="white" onClick={handleCloseDrawer}>
                  {intl.formatMessage({
                    id: translationKeys.job_edit_information_drawer_cancel_label,
                  })}
                </Button>
                <Button size="x-large" type="submit" isLoading={updateJob.isPending} disabled={isJobReadyForFinance}>
                  {intl.formatMessage({
                    id: translationKeys.job_edit_information_drawer_submit_label,
                  })}
                </Button>
              </footer>
            </Form>
          </Drawer>
        );
      }}
    </Formik>
  );
};

export default EditInvoicingDrawer;
